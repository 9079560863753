import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import {
  Button,
  Form,
  Input,
  Checkbox,
  Row,
  Col,
} from 'antd';
import { UnlockOutlined, MailOutlined } from '@ant-design/icons';
import {
  ROUTE_LOGIN,
  ROUTE_TERMS_AND_CONDITIONS,
  ROUTE_PRIVACY_POLICY,
} from 'constants/routes';
import { passwordValidator } from 'helpers/validators';
import ErrorText from 'components/common/ErrorText';
import styles from '../index.module.scss';

const BasicInfo = ({ history, setCurrentUser }) => {
  const [isLoading, setLoading] = useState(false);
  const [isAgree, setIsAgree] = useState(false);
  const [error, setError] = useState('');

  const handleCreateAccount = useCallback(async ({
     email,
     password,
   }) => {
    setLoading(true);
    try {
      const username = email.toLowerCase();
      setCurrentUser({ username, password });
      await Auth.signUp({
        username,
        password,
        attributes: { email: username },
      });
      setLoading(false);
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  }, [setCurrentUser]);

  const handleClickBack = useCallback(() => history.push(ROUTE_LOGIN), [history]);

  const handleClickTermsAndConditions = useCallback(e => {
    e.preventDefault();
    history.push(ROUTE_TERMS_AND_CONDITIONS);
  }, [history]);

  const handleClickPrivacyPolicy = useCallback(e => {
    e.preventDefault();
    history.push(ROUTE_PRIVACY_POLICY);
  }, [history]);

  return (
    <Row justify="center">
      <Col span={24}>
        <h1 className={styles.loginH1}>Create account</h1>
        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={handleCreateAccount}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Please input your email!',
              },
            ]}
          >
            <Input
              allowClear
              placeholder="Email"
              prefix={<MailOutlined />}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password',
              },
              () => passwordValidator({ passwordLength: 8 }),
            ]}
          >
            <Input.Password
              allowClear
              placeholder="Password"
              prefix={<UnlockOutlined />}
            />
          </Form.Item>

          <div className={styles.agreementItem}>
            <Form.Item
              name="remember"
              valuePropName="checked"
              rules={[
                {
                  type: 'boolean',
                  required: true,
                  message: 'Please confirm your agreement',
                },
              ]}

            >
              <Checkbox onChange={e => setIsAgree(e?.target?.checked)} />
              <span className={styles.agreement}>
                I agree to the <a
                href="/"
                onClick={handleClickTermsAndConditions}
                >Terms & Conditions</a> and have read the <a
                onClick={handleClickPrivacyPolicy}
                href="/"
                >Privacy Policy</a>
              </span>
            </Form.Item>
          </div>

          <Form.Item className={styles.checkboxItem}>
            <Row>
              <Col span={12}>
                <Button
                  htmlType="submit"
                  disabled={isLoading}
                  onClick={handleClickBack}
                >
                  Back
                </Button>
              </Col>
              <Col span={12} align="right">
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={isLoading || !isAgree}
                  loading={isLoading}
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Form.Item>
          <ErrorText text={error} />
        </Form>
      </Col>
    </Row>
  );
};

BasicInfo.defaultProps = {
  history: null,
  setCurrentUser: null,
};

BasicInfo.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  setCurrentUser: PropTypes.func,
};

export default BasicInfo;
