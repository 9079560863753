import React, {
 useContext, useState, useEffect, useCallback,
} from 'react';
import { graphql } from 'react-apollo';
import { GET_UNIVERSITIES_LIST_FOR_CREATE_ACCOUNT } from 'api/queries';
import PropTypes from 'prop-types';
import {
  Layout,
  Select,
  Avatar,
  Button,
} from 'antd';
import {
  AntDesignOutlined,
} from '@ant-design/icons';
import { ProfileContext } from 'contextProviders/ProfileProvider';
import { ROUTE_UNIVERSITY } from 'constants/routes';
import EditUniversityProfileTypeModal from './EditUniversityProfileTypeModal';
import styles from './index.module.scss';

const { Option } = Select;
const { Content } = Layout;

const SelectUniversity = ({
  history,
  universitiesList: { listUniversities: { universities = [] } = {} } = {},
}) => {
  const {
    fetchedMyProfile: {
      getMyProfile: {
        university: {
          name,
        } = {},
      } = {},
    } = {},
  } = useContext(ProfileContext);

  const [university, setUniversity] = useState({});
  const [isProfileTypeModalVisible, setIsProfileTypeModalVisible] = useState(false);

  useEffect(() => {
    if (name) {
      const search = universities?.find(u => u?.name === name);
      setUniversity(search);
    }
  }, [name, universities, setUniversity]);

  const openUniversityPage = useCallback(() => {
    if (university?.name) {
      history.push(ROUTE_UNIVERSITY.replace(':universityName', university.name));
    }
  }, [university, history]);

  const handleChange = useCallback(value => {
    const search = universities.find(u => u?.name === value) || {};
    setUniversity(search);
  }, [universities]);

  const handleOpenModal = useCallback(() => {
    const search = universities.find(u => u?.name === university?.name) || {};

    if (search.profileTypes?.length > 0) {
      setIsProfileTypeModalVisible(true);
    } else {
      openUniversityPage();
    }
  }, [universities, university, openUniversityPage]);

  if (!university?.name || universities.length === 0) return null;

  return (
    <>
      <Content>
        <div className="site-layout-background">
          <div className="university-select">
            <Select
            defaultValue={university?.name}
            bordered={false}
            onChange={handleChange}
            >
              {universities?.map(u => (
                <Option
                value={u.name}
                key={u.name}
                >{u.centerName}</Option>
            ))}
            </Select>
          </div>
          <div className={styles.wrapperUniversity}>
            <Avatar
              icon={<AntDesignOutlined />}
              size={104}
              src={university?.profilePic}
            />
            <div className={styles.universityName}>{university.centerName}</div>
            <Button
              type="primary"
              onClick={handleOpenModal}
              className={styles.universityView}
            >
              View profile
            </Button>
          </div>
        </div>
      </Content>
      <EditUniversityProfileTypeModal
        hideModal={() => setIsProfileTypeModalVisible(false)}
        history={history}
        university={university}
        openUniversityPage={openUniversityPage}
        visible={university?.name && isProfileTypeModalVisible}
      />
    </>
  );
};

SelectUniversity.defaultProps = {
  history: null,
};

SelectUniversity.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  // eslint-disable-next-line react/require-default-props
  universitiesList: PropTypes.object,
};

const withGetUniversities = graphql(GET_UNIVERSITIES_LIST_FOR_CREATE_ACCOUNT, {
  name: 'universitiesList',
});

export default withGetUniversities(SelectUniversity);
