/* eslint-disable prefer-promise-reject-errors */

const confirmNewPasswordValidator = ({ getFieldValue }) => ({
  // TODO: move vlidators to separate file
  validator(rule, value) {
    const passwordValue = getFieldValue('newPassword');
    if (!value || passwordValue === value) {
      return Promise.resolve();
    }

    return Promise.reject('The two passwords that you entered do not match!');
  },
});

const passwordValidator = ({ passwordLength }) => ({
  validator(rule, value) {
    if (value && value.length) {
      if (value.length < passwordLength) {
        return Promise.reject(`Should contain min ${passwordLength} symbols`);
      }
      const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{1,100}$/;
      const passValidation = regex.test(value);
      if (passValidation) {
        return Promise.resolve();
      }
      return Promise.reject('Should contain at least one uppercase, one lowercase and one number');
    }
    return Promise.resolve();
  },
});

export { confirmNewPasswordValidator, passwordValidator };
