import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Col,
} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

const Coach = ({
 coach: {
   sports = [],
   firstName,
   lastName,
   profilePic,
 } = {},
}) => {
  return (
    <>
      <Col span={24}>
        {/* TODO: currently it should be hidden */}
        {false && sports?.map(s => (
          <span
            className={styles.sportLabel}
            key={s?.sport?.displayName}
          >
            {s?.sport?.displayName}
          </span>
        ))}
      </Col>
      <Col span={24}>
        <div className={styles.coach}>
          <Avatar size={50} icon={<UserOutlined />} src={profilePic} />
          <div>
            <div className={styles.coachName}>{`${firstName} ${lastName}`}</div>
            <div className={styles.staffTitle}>Coach</div>
          </div>
        </div>
      </Col>
    </>
  );
};

Coach.defaultProps = {
  coach: null,
};

Coach.propTypes = {
  coach: PropTypes.shape({
    sports: PropTypes.arrayOf(
      PropTypes.shape({
        sport: PropTypes.shape({
          displayName: PropTypes.string,
        }),
      }),
    ),
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    profilePic: PropTypes.oneOfType([
      null,
      PropTypes.string,
    ]),
  }),
};

export default Coach;
