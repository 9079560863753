/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import {
  Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { Auth, Hub } from 'aws-amplify';
import Login from 'pages/Login/Login';
import ForgotPassword from 'pages/Login/ForgotPassword';
import ResetPassword from 'pages/Login/ResetPassword';
import CreateAccount from 'pages/Login/CreateAccount';
import Dashboard from 'pages/Dashboard';
import Modal from 'components/common/Modal';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import TermsAndConditions from 'pages/TermsAndConditions';
import ContactUs from 'pages/ContactUs';

import history from 'helpers/history';

import {
  ROUTE_LOGIN,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_RESET_PASSWORD,
  ROUTE_CREATE_ACCOUNT,
  ROUTE_MY_PROFILE,
  ROUTE_SELECT_UNIVERSITY,
  ROUTE_UNIVERSITY,
  ROUTE_BOOKING,
  ROUTE_PRIVACY_POLICY,
  ROUTE_TERMS_AND_CONDITIONS,
  ROUTE_CONTACT_US,
} from 'constants/routes';

const Routes = () => {
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const [authorizedUser, setAuthorizedUser] = useState(null);

  const checkIfIsLoggedIn = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        setAuthorizedUser(user);
      }
    } catch (e) {
      console.log(e);
    }
    setIsAuthChecked(true);
  };

  useEffect(() => {
    try {
      checkIfIsLoggedIn();

      const onAuthEvent = (payload) => {
        const { event, data } = payload;

        console.log(payload);
        console.log(event);

        // setAuthorizedUser(data);
        switch (event) {
          case 'signIn': {
            setAuthorizedUser(data);
            break;
          }
          case 'signOut': {
            // setUserType();
            break;
          }
          case 'signUp': {
            setAuthorizedUser(data);
            break;
          }
          default: {
            console.log(event);
          }
        }
      };

      Hub.listen('auth', ({ payload }) => {
        onAuthEvent(payload);
      });

      return () => {
        Hub.remove('auth', ({ payload }) => {
          onAuthEvent(payload);
        });
      };
    } catch (e) {
      // setUserType();
      console.log(e);
    }
  }, []);

  if (!isAuthChecked) {
    return null;
  }

  return (

    <Router history={history}>
      <>
        <Route
          render={() => {
            return (
              <>
                <Modal />

                <Switch>
                  <Route path={ROUTE_TERMS_AND_CONDITIONS} component={TermsAndConditions} exact />

                  <Route path={ROUTE_PRIVACY_POLICY} component={PrivacyPolicy} exact />

                  <Route path={ROUTE_CONTACT_US} component={ContactUs} exact />

                  <Route
                    render={(props) => (
                      <CreateAccount
                        authorizedUser={authorizedUser}
                        checkIfIsLoggedIn={checkIfIsLoggedIn}
                        {...props}
                      />
                    )}
                    path={ROUTE_CREATE_ACCOUNT}
                    exact
                  />

                  {!!authorizedUser && (
                    <>
                      <Route
                        render={(props) => (
                          <Dashboard {...props} checkIfIsLoggedIn={checkIfIsLoggedIn} />
                        )}
                        path={ROUTE_MY_PROFILE}
                        exact
                      />
                      <Route
                        render={(props) => (
                          <Dashboard {...props} checkIfIsLoggedIn={checkIfIsLoggedIn} />
                        )}
                        path={ROUTE_SELECT_UNIVERSITY}
                        exact
                      />
                      <Route
                        render={(props) => (
                          <Dashboard {...props} checkIfIsLoggedIn={checkIfIsLoggedIn} />
                        )}
                        path={ROUTE_UNIVERSITY}
                        exact
                      />
                      <Route
                        render={(props) => (
                          <Dashboard {...props} checkIfIsLoggedIn={checkIfIsLoggedIn} />
                        )}
                        path={ROUTE_BOOKING}
                        exact
                      />
                      {/* <Redirect to={ROUTE_MY_PROFILE} /> */}
                    </>
                  )}

                  {!authorizedUser && (
                  <>
                    <Route path={ROUTE_LOGIN} component={Login} exact />
                    <Route path={ROUTE_FORGOT_PASSWORD} component={ForgotPassword} exact />
                    <Route path={`${ROUTE_RESET_PASSWORD}/:email`} component={ResetPassword} exact />
                    <Redirect to={ROUTE_LOGIN} />
                  </>
                  )}
                </Switch>
              </>
            );
          }}
        />
      </>
    </Router>
  );
};

export default Routes;
