import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';

const { Text } = Typography;

const ErrorText = ({ text, className }) => {
  return (
    <>
      {text ? <Text type="danger" className={className}>{text}</Text> : null}
    </>
  );
};

ErrorText.defaultProps = {
  className: '',
  text: '',
};

ErrorText.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

export default ErrorText;
