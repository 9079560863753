import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ProfileProvider from 'contextProviders/ProfileProvider';
import EditProfileProvider from 'contextProviders/EditProfileProvider';
import { STEPS_INDEXES } from 'constants/registrationSteps';
import SelectSportsStep from 'components/Login/CreateAccount/SelectSports';
import SelectLevelsStep from 'components/Login/CreateAccount/SelectLevels';

const AddSportsModal = ({ toggleModal }) => {
  const [currentStep, setCurrentStep] = useState(STEPS_INDEXES.SELECTED_SPORTS);

  return (
    <ProfileProvider>
      <EditProfileProvider>
        {currentStep === STEPS_INDEXES.SELECTED_SPORTS && (
          <SelectSportsStep
            setCurrentStep={setCurrentStep}
            setLockedSteps={() => {}}
            goToStep={setCurrentStep}
            isModal
          />
        )}
        {currentStep === STEPS_INDEXES.SELECTED_LEVELS && (
          <SelectLevelsStep
            setCurrentStep={setCurrentStep}
            goToStep={setCurrentStep}
            toggleModal={toggleModal}
            isModal
          />
        )}
      </EditProfileProvider>
    </ProfileProvider>
  );
};

AddSportsModal.defaultProps = {
  toggleModal: null,
};

AddSportsModal.propTypes = {
  toggleModal: PropTypes.func,
};

export default AddSportsModal;
