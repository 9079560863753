import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
} from 'antd';
import { ROUTE_LOGIN } from 'constants/routes';
import LoginComponent from 'components/Login/Login';
import Logo from 'components/common/Logo';
import styles from './index.module.scss';

const Login = ({ history }) => {
  const handleClickLogo = useCallback(() => history.push(ROUTE_LOGIN), [history]);

  return (
    <Row className={styles?.loginPage} gutter={16} type="block">
      <Col
        className={styles?.left}
        xs={{ span: 0 }}
        sm={{ span: 0 }}
        md={{ span: 0 }}
        lg={{ span: 6 }}
        xl={{ span: 12 }}
      >
        <Logo onClick={handleClickLogo} />
      </Col>
      <Col
        className={styles?.right}
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 20 }}
        lg={{ span: 18 }}
        xl={{ span: 12 }}
      >
        <LoginComponent history={history} />
      </Col>
    </Row>
  );
};

Login.defaultProps = {
  history: {},
};

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default Login;
