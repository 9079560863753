import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { GET_CAMPUS_SPORT_CLASSES } from 'api/queries';
import SportClass from './SportClass';

const SportClasses = ({
  fetchedMyClasses,
  fetchedMyClasses: {
    loading,
    getCampusSportClasses,
  } = {},
  setIsLoading,
}) => {
  useEffect(() => setIsLoading(loading),
    [setIsLoading, loading]);

  const bookedClassId = getCampusSportClasses?.find(i => i?.isBooked)?.classId;

  return (
    <>
      {getCampusSportClasses?.map(sc => (
        <SportClass
          sportClass={sc}
          key={sc?.classId}
          fetchedMyClasses={fetchedMyClasses}
          bookedClassId={bookedClassId}
        />
    ))}
    </>
  );
};

SportClasses.defaultProps = {
  fetchedMyClasses: null,
  setIsLoading: null,
};

SportClasses.propTypes = {
  fetchedMyClasses: PropTypes.shape({
    loading: PropTypes.bool,
    getCampusSportClasses: PropTypes.arrayOf(
      PropTypes.shape({
        isBooked: PropTypes.bool,
      }),
),
  }),
  setIsLoading: PropTypes.func,
};

const withData = graphql(GET_CAMPUS_SPORT_CLASSES, {
  name: 'fetchedMyClasses',
  options: ({
    university,
    campus,
    category,
    sport,
    date,
  }) => ({
    variables: {
      university,
      campus,
      category,
      sport,
      date,
    },
  }),
});

export default withData(SportClasses);
