import React, { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ClockCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Typography,
} from 'antd';
import { BookingContext } from 'contextProviders/BookingProvider';
import { ModalContext } from 'contextProviders/ModalProvider';
import styles from './index.module.scss';

const { Text } = Typography;

const SportClass = ({
  fetchedMyClasses,
  sportClass: {
    classId,
    date,
    endTime,
    isBooked,
    maxParticipants,
    name,
    participantsCount,
    sportName,
    startTime,
    status,
  } = {},
  bookedClassId,
}) => {
  const {
    bookCampusSportClass,
    unBookCampusSportClass,
  } = useContext(BookingContext);
  const {
    openModal,
    toggleModal,
  } = useContext(ModalContext);

  const [isLoading, setIsLoading] = useState(false);

  const bookClass = useCallback(async id => {
    setIsLoading(true);
    const variables = { classId: id };
    await bookCampusSportClass({ variables });
    await fetchedMyClasses.refetch();
    setIsLoading(false);
  }, [bookCampusSportClass, fetchedMyClasses]);

  const unBookClass = useCallback(async id => {
    setIsLoading(true);
    const variables = { classId: id };
    await unBookCampusSportClass({ variables });
    await fetchedMyClasses.refetch();
    setIsLoading(false);
  }, [unBookCampusSportClass, fetchedMyClasses]);

  const showConfirmModal = useCallback(() => {
    const footer = [
      <Button
        key="back"
        onClick={() => toggleModal(false)}
      >
        Cancel
      </Button>,
      <Button
        key="back"
        type="primary"
        onClick={async () => {
          toggleModal(false);
          await unBookClass(bookedClassId);
          await bookClass(classId);
        }}
      >
        Yes
      </Button>,
    ];
    openModal({
      modalContent: <>
        <span className={styles.confirm}>
          Cannot book the same sport twice in the same day.<br />
          Are you sure you want to unbook previously selected timeslot?
        </span>
      </>,
      footer,
    });
  }, [classId, bookedClassId, bookClass, unBookClass, openModal, toggleModal]);

  const handleUnBook = useCallback(() => unBookClass(classId),
    [classId, unBookClass]);

  const handleBook = useCallback(async () => {
    if (bookedClassId) {
      showConfirmModal();
    } else {
      await bookClass(classId);
    }
  }, [showConfirmModal, bookedClassId, bookClass, classId]);

  return (
    <div className={styles.sportInfo}>
      <h4>{sportName} - {name}:</h4>
      <div className={styles.sportDate}>
        <ClockCircleOutlined /> {moment(date).format('dddd DD/MM/YYYY')}
      </div>
      <div className={styles.sportTimeBox}>
        <div>
          <div className={styles.sportTime}>Time {startTime} - {endTime}</div>
          <div className={styles.participants}>{participantsCount}/{maxParticipants} participants</div>
        </div>

        {isBooked && status !== 'canceled' && (
          <Button
            onClick={handleUnBook}
            disabled={isLoading}
            loading={isLoading}
          >
            UnBook
          </Button>
        )}

        {!isBooked && status !== 'canceled' && (
          <>
            {participantsCount < maxParticipants && (
            <Button
            type="primary"
            onClick={handleBook}
            disabled={isLoading}
            loading={isLoading}
            >
              Book
            </Button>
          )}

            {participantsCount === maxParticipants && (
              <Text type="danger" style={{ marginLeft: 'auto' }}>This Sport Session is Full</Text>
            )}
          </>
        )}

        {status === 'canceled' && (
          <Button
            type="danger"
          >
            Cancelled
          </Button>
        )}

      </div>
    </div>
  );
};

SportClass.defaultProps = {
  fetchedMyClasses: null,
  sportClass: null,
  bookedClassId: null,
};

SportClass.propTypes = {
  fetchedMyClasses: PropTypes.shape({
    refetch: PropTypes.func,
  }),
  sportClass: PropTypes.shape({
    classId: PropTypes.string,
    date: PropTypes.string,
    endTime: PropTypes.string,
    isBooked: PropTypes.bool,
    maxParticipants: PropTypes.number,
    name: PropTypes.string,
    participantsCount: PropTypes.number,
    sportName: PropTypes.string,
    startTime: PropTypes.string,
    status: PropTypes.oneOfType([null, PropTypes.string]),
  }),
  bookedClassId: PropTypes.oneOfType([
    null,
    undefined,
    PropTypes.string,
  ]),
};

export default SportClass;
