import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
} from 'antd';
import { ROUTE_LOGIN } from 'constants/routes';
import CreateAccountComponent from 'components/Login/CreateAccount';
import Logo from 'components/common/Logo';
import Close from 'components/common/Close';
import ProfileProvider from 'contextProviders/ProfileProvider';
import styles from './index.module.scss';

const CreateAccount = ({ history, authorizedUser, checkIfIsLoggedIn }) => {
  const handleClickLogo = useCallback(() => history.push(ROUTE_LOGIN), [history]);
  const handleClickClose = useCallback(() => history.push(ROUTE_LOGIN), [history]);

  return (
    <Row className={styles?.loginPage} gutter={16} type="block">
      <Col span={24}>
        <Logo onClick={handleClickLogo} />
        <Close onClick={handleClickClose} />
        <ProfileProvider>
          <CreateAccountComponent
            history={history}
            authorizedUser={authorizedUser}
            checkIfIsLoggedIn={checkIfIsLoggedIn}
          />
        </ProfileProvider>
      </Col>
    </Row>
  );
};

CreateAccount.defaultProps = {
  history: {},
  authorizedUser: null,
  checkIfIsLoggedIn: null,
};

CreateAccount.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  authorizedUser: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.shape({}),
  ]),
  checkIfIsLoggedIn: PropTypes.func,
};

export default CreateAccount;
