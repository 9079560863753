import React from 'react';
import PropTypes from 'prop-types';
import DashboardLayout from 'components/Dashboard/DashboardLayout';
import ProfileProvider from 'contextProviders/ProfileProvider';
import EditProfileProvider from 'contextProviders/EditProfileProvider';

const Dashboard = ({ history, checkIfIsLoggedIn, match }) => (
  <ProfileProvider>
    <EditProfileProvider>
      <DashboardLayout
        history={history}
        checkIfIsLoggedIn={checkIfIsLoggedIn}
        match={match}
      />
    </EditProfileProvider>
  </ProfileProvider>
  );

Dashboard.defaultProps = {
  history: null,
  checkIfIsLoggedIn: null,
  match: null,
};

Dashboard.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  checkIfIsLoggedIn: PropTypes.func,
  match: PropTypes.shape({}),
};

export default Dashboard;
