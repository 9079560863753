import React, {
 useState, useCallback, useContext, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import {
  Row, Col, Button, Modal, Select,
} from 'antd';
import { GET_MY_UNIVERSITY_PROFILE_TYPE } from 'api/queries';
import { ProfileContext } from 'contextProviders/ProfileProvider';
import { UserOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

const { Option } = Select;

const EditUniversityProfileTypeModal = ({
  hideModal,
  fetchedMyUniversityProfileType: {
    getMyUniversityProfileType,
  } = {},
  university: {
    name: universityName,
    profileTypes = [],
  },
  openUniversityPage,
  visible,
}) => {
  const {
    updateMyUniversityProfileType,
  } = useContext(ProfileContext);

  const [profileType, setProfileType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!visible) return;

    if (getMyUniversityProfileType) {
      openUniversityPage();
    } else if (profileTypes?.length > 0) {
      setProfileType(profileTypes[0]);
    }
  }, [getMyUniversityProfileType, profileTypes, setProfileType, openUniversityPage, visible]);

  const handleEditProfileType = useCallback(async () => {
    const variables = { universityName, profileType };
    setIsLoading(true);
    await updateMyUniversityProfileType({ variables });
    setIsLoading(false);
    hideModal();
    openUniversityPage();
  }, [profileType, universityName, openUniversityPage, hideModal, updateMyUniversityProfileType]);

  const handleChangeSelect = useCallback(value => {
    setProfileType(value);
  }, [setProfileType]);

  if (!profileType || profileTypes?.length === 0) return null;

  return (
    <Modal
      closable
      visible={visible}
      onCancel={hideModal}
      footer={[
        <Button
          key="back"
          onClick={hideModal}
          disabled={isLoading}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isLoading}
          disabled={isLoading || !profileType}
          onClick={handleEditProfileType}
        >
          Yes
        </Button>,
      ]}
    >
      <Row justify="center" type="flex">
        <Col align="center" span={24}>
          <h4>To continue, choose your category</h4>
        </Col>
        <Col span={24} className={styles.profileType}>
          <Select
            onChange={handleChangeSelect}
            style={{ width: '100%' }}
            defaultValue={profileType}
            disabled={!profileType || isLoading}
          >
            {profileTypes.map(item => (
              <Option
              value={item}
              key={item}

              >
                <UserOutlined
                  className={styles.profileTypeIcon}
                /> {item}</Option>
            ))}
          </Select>
        </Col>
      </Row>
    </Modal>
  );
};

EditUniversityProfileTypeModal.defaultProps = {
  hideModal: null,
  openUniversityPage: null,
  university: null,
  fetchedMyUniversityProfileType: null,
  visible: null,
};

EditUniversityProfileTypeModal.propTypes = {
  hideModal: PropTypes.func,
  fetchedMyUniversityProfileType: PropTypes.shape({
    getMyUniversityProfileType: PropTypes.oneOfType([
      null,
      PropTypes.string,
    ]),
  }),
  university: PropTypes.shape({
    name: PropTypes.string,
    profileTypes: PropTypes.oneOfType([
      null,
      PropTypes.arrayOf(PropTypes.string),
    ]),
  }),
  openUniversityPage: PropTypes.func,
  visible: PropTypes.bool,
};

const withGetMyUniversityProfileType = graphql(GET_MY_UNIVERSITY_PROFILE_TYPE, {
  name: 'fetchedMyUniversityProfileType',
  options: ({ university: { name: universityName } }) => ({ variables: { universityName } }),
});

export default withGetMyUniversityProfileType(EditUniversityProfileTypeModal);
