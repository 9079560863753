import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import {
  BOOK_CAMPUS_SPORT_CLASS,
  UNBOOK_CAMPUS_SPORT_CLASS,
} from 'api/mutations';
import { ITEMS_PER_PAGE } from 'constants/general';
import { GET_MY_CAMPUS_SPORT_CLASSES } from '../api/queries';

export const BookingContext = React.createContext();

const BookingProvider = ({
 children,
 bookCampusSportClass,
 unBookCampusSportClass,
 fetchedMyClasses,
}) => {
  const updaters = {
    bookCampusSportClass,
    unBookCampusSportClass,
    fetchedMyClasses,
  };

  return (
    <>
      <BookingContext.Provider
        value={{
          ...updaters,
        }}
      >
        {children}
      </BookingContext.Provider>
    </>
  );
};

const withBookCampusSportClass = graphql(BOOK_CAMPUS_SPORT_CLASS, {
  name: 'bookCampusSportClass',
});

const withUnBookCampusSportClass = graphql(UNBOOK_CAMPUS_SPORT_CLASS, {
  name: 'unBookCampusSportClass',
});

const withMyCampusSportClasses = graphql(GET_MY_CAMPUS_SPORT_CLASSES, {
  name: 'fetchedMyClasses',
  options: () => ({
    variables: {
      limit: ITEMS_PER_PAGE,
      nextToken: null,
    },
  }),
});

const withGraphQl = compose(
  withBookCampusSportClass,
  withUnBookCampusSportClass,
  withMyCampusSportClasses,
);

BookingProvider.defaultProps = {
  children: null,
  bookCampusSportClass: null,
  unBookCampusSportClass: null,
};

BookingProvider.propTypes = {
  children: PropTypes.any,
  bookCampusSportClass: PropTypes.func,
  unBookCampusSportClass: PropTypes.func,
};

export default withGraphQl(BookingProvider);
