import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Logo from 'components/common/Logo';

const TermsAndConditions = ({ history }) => {
  const handleClickLogo = useCallback(() => history.push('/'), [history]);

  return (
    <div style={{ padding: '80px' }}>
      <Logo onClick={handleClickLogo} />

      <>
        <p style={{ marginBottom: '0.32in', lineHeight: '116%' }}><font color="#31363a"><font size={5}><b>Terms
          and conditions</b></font></font></p>
        <p style={{ marginBottom: '0.32in', lineHeight: '116%' }}><font color="#31363a"><font size={5}><b>Things
          You Cannot Do</b></font></font></p>
        <p style={{ marginBottom: '0.15in', lineHeight: '100%' }}><font color="#31363a"><font
size={2}
                                                                                            style={{ fontSize: '10pt' }}
        >When
          accessing or using the Services, you must respect others and their
          rights, including by following these Terms and the Content Policy, so
          that we all may continue to use and enjoy the Services. When
          accessing or using our Services, you will not:</font></font></p>
        <ul>
          <li><p style={{ marginTop: '0.15in', marginBottom: '0.19in', lineHeight: '100%' }}>
            <font color="#31363a"><font size={2} style={{ fontSize: '10pt' }}>Create or
              submit Content that violates our Content Policy or attempt to
              circumvent any content-filtering techniques we use;</font></font></p>
          </li>
        </ul>
        <ul>
          <li><p style={{ marginTop: '0.19in', marginBottom: '0.19in', lineHeight: '100%' }}>
            <font color="#31363a"><font size={2} style={{ fontSize: '10pt' }}>Use the
              Services to violate applicable law or infringe any person or
              entity's intellectual property or any other proprietary rights;</font></font></p>
          </li>
        </ul>
        <ul>
          <li><p style={{ marginTop: '0.19in', marginBottom: '0.19in', lineHeight: '100%' }}>
            <font color="#31363a"><font size={2} style={{ fontSize: '10pt' }}>Attempt
              to gain unauthorized access to another user’s Account or to the
              Services (or to other computer systems or networks connected to or
              used together with the Services);</font></font></p>
          </li>
        </ul>
        <ul>
          <li><p style={{ marginTop: '0.19in', marginBottom: '0.19in', lineHeight: '100%' }}>
            <font color="#31363a"><font size={2} style={{ fontSize: '10pt' }}>Upload,
              transmit, or distribute to or through the Services any computer
              viruses, worms, or other software intended to interfere with the
              intended operation of a computer system or data;</font></font></p>
          </li>
        </ul>
        <ul>
          <li><p style={{ marginTop: '0.19in', marginBottom: '0.19in', lineHeight: '100%' }}>
            <font color="#31363a"><font size={2} style={{ fontSize: '10pt' }}>Use the
              Services to harvest, collect, gather or assemble information or data
              regarding the Services or users of the Services except as permitted
              in these Terms or in a separate agreement with Nova Sports App;</font></font></p>
          </li>
        </ul>
        <ul>
          <li><p style={{ marginTop: '0.19in', marginBottom: '0.15in', lineHeight: '100%' }}>
            <font color="#31363a"><font size={2} style={{ fontSize: '10pt' }}>Use the
              Services in any manner that could interfere with, disrupt,
              negatively affect, or inhibit other users from fully enjoying the
              Services or that could damage, disable, overburden, or impair the
              functioning of the Services in any manner;</font></font></p>
          </li>
        </ul>
        <p style={{
          marginLeft: '0.5in', marginTop: '0.15in', marginBottom: '0.15in', lineHeight: '100%',
        }}
        >
          <br /><br />
        </p>
        <p style={{ marginBottom: '0.32in', lineHeight: '116%' }}><font color="#31363a"><font size={5}><b>Content
          Policy</b></font></font></p>
        <p style={{ marginBottom: '0.15in', lineHeight: '100%' }}><font color="#31363a"><font
size={2}
                                                                                            style={{ fontSize: '10pt' }}
        >Content
          is prohibited if it</font></font></p>
        <ul>
          <li><p style={{ marginTop: '0.15in', marginBottom: '0.19in', lineHeight: '100%' }}>
            <font color="#31363a"><font size={2} style={{ fontSize: '10pt' }}>Is
              illegal</font></font></p>
          </li>
          <li><p style={{ marginTop: '0.19in', marginBottom: '0.19in', lineHeight: '100%' }}>
            <font color="#31363a"><font size={2} style={{ fontSize: '10pt' }}>Is
              involuntary pornography</font></font></p>
          </li>
          <li><p style={{ marginTop: '0.19in', marginBottom: '0.19in', lineHeight: '100%' }}>
            <font color="#31363a"><font size={2} style={{ fontSize: '10pt' }}>Is sexual
              or suggestive content involving minors</font></font></p>
          </li>
          <li><p style={{ marginTop: '0.19in', marginBottom: '0.19in', lineHeight: '100%' }}>
            <font size={2} style={{ fontSize: '10pt' }}>Encourages or incites violence</font></p>
          </li>
          <li><p style={{ marginTop: '0.19in', marginBottom: '0.19in', lineHeight: '100%' }}>
            <font size={2} style={{ fontSize: '10pt' }}>Threatens, harasses, or bullies
            </font><font color="#31363a"><font size={2} style={{ fontSize: '10pt' }}>or
              encourages others to do so</font></font></p>
          </li>
          <li><p style={{ marginTop: '0.19in', marginBottom: '0.19in', lineHeight: '100%' }}>
            <font color="#31363a"><font size={2} style={{ fontSize: '10pt' }}>Is
              personal and confidential information</font></font></p>
          </li>
          <li><p style={{ marginTop: '0.19in', marginBottom: '0.19in', lineHeight: '100%' }}>
            <font size={2} style={{ fontSize: '10pt' }}>Impersonates</font><font color="#31363a"><font
size={2}
                                                                                                     style={{ fontSize: '10pt' }}
            >
              an individual or entity in a misleading or deceptive manner</font></font></p>
          </li>
          <li><p style={{ marginTop: '0.19in', marginBottom: '0.19in', lineHeight: '100%' }}>
            <font color="#31363a"><font size={2} style={{ fontSize: '10pt' }}>Uses Nova
              Sports App to solicit or facilitate any transaction or gift
              involving certain goods and services</font></font></p>
          </li>
          <li><p style={{ marginTop: '0.19in', marginBottom: '0.15in', lineHeight: '100%' }}>
            <font color="#31363a"><font size={2} style={{ fontSize: '10pt' }}>Is spam</font></font></p>
          </li>
        </ul>
        <p style={{ marginBottom: '0in' }}><br />
        </p>
      </>

    </div>
  );
};

TermsAndConditions.defaultProps = {
  history: {},
};

TermsAndConditions.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default TermsAndConditions;
