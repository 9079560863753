import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
} from 'antd';
import { ROUTE_LOGIN } from 'constants/routes';
import Logo from 'components/common/Logo';
import Close from 'components/common/Close';
import ResetPasswordComponent from 'components/Login/ResetPassword';
import styles from './index.module.scss';

const ResetPassword = ({ history, match }) => {
  const handleClickLogo = useCallback(() => history.push(ROUTE_LOGIN), [history]);
  const handleClickClose = useCallback(() => history.push(ROUTE_LOGIN), [history]);

  return (
    <Row className={styles?.loginPage} gutter={16} type="block">
      <Col
        className={styles?.left}
        xs={{ span: 0 }}
        sm={{ span: 0 }}
        md={{ span: 0 }}
        lg={{ span: 6 }}
        xl={{ span: 12 }}
      >
        <Logo onClick={handleClickLogo} />
      </Col>
      <Col
        className={styles?.right}
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 20 }}
        lg={{ span: 18 }}
        xl={{ span: 12 }}
      >
        <Close onClick={handleClickClose} />
        <ResetPasswordComponent history={history} match={match} />
      </Col>
    </Row>
  );
};

ResetPassword.defaultProps = {
  history: {},
  match: {},
};

ResetPassword.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  match: PropTypes.shape({}),
};

export default ResetPassword;
