import React, {
  useState, useCallback, useEffect, useContext,
} from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
} from 'antd';
import { ProfileContext } from 'contextProviders/ProfileProvider';
import EditProfileProvider from 'contextProviders/EditProfileProvider';
import {
  STEPS_INDEXES, ALL_STEPS, REGISTRATION_STEPS, SPORTS_STEPS,
} from 'constants/registrationSteps';
import BasicInfoStep from './BasicInfo';
import VerificationStep from './Verification';
import EditProfileStep from './EditProfile';
import SelectSportsStep from './SelectSports';
import SelectLevelsStep from './SelectLevels';
import Steps from './Steps';
import styles from '../index.module.scss';

const CreateAccountSteps = ({ history, authorizedUser, checkIfIsLoggedIn }) => {
  const {
    fetchedMyProfile: { getMyProfile } = {},
  } = useContext(ProfileContext);

  const [currentStep, setCurrentStep] = useState(STEPS_INDEXES.BASIC_INFO);
  const [currentUser, setCurrentUser] = useState({});
  const [lockedSteps, setLockedSteps] = useState([]);

  const {
    signInUserSession,
    user: {
      username,
    } = {},
  } = authorizedUser || {};

  useEffect(() => {
    switch (true) {
      case !authorizedUser:
        setLockedSteps(ALL_STEPS);
        setCurrentStep(STEPS_INDEXES.BASIC_INFO);
        break;

      case !signInUserSession:
        setLockedSteps(ALL_STEPS);
        setCurrentStep(STEPS_INDEXES.VERIFICATION);
        break;

      case !getMyProfile:
        setLockedSteps([...REGISTRATION_STEPS, ...SPORTS_STEPS]);
        setCurrentStep(STEPS_INDEXES.EDIT_PROFILE);
        break;

      case !!getMyProfile && getMyProfile.sports.length === 0:
        setLockedSteps([...REGISTRATION_STEPS, STEPS_INDEXES.SELECTED_LEVELS]);
        break;

      default:
        setLockedSteps(REGISTRATION_STEPS);
    }
  }, [authorizedUser, getMyProfile, signInUserSession]);

  const goToStep = useCallback((value, forceUpdate) => {
      if (forceUpdate || !lockedSteps.includes(value)) {
        setCurrentStep(value);
      }
    },
    [lockedSteps]);

  return (

    <Row className={styles.wrapperCreate}>
      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 20 }}
        lg={{ span: 20 }}
        xl={{ span: 20 }}
      >
        <Steps onChange={goToStep} currentStep={currentStep} lockedSteps={lockedSteps} />

        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 18 }}
            md={{ span: 16 }}
            lg={{ span: 14 }}
            xl={{ span: 12 }}
            style={{ margin: '0 auto' }}
          >
            {currentStep === STEPS_INDEXES.BASIC_INFO && (
              <BasicInfoStep
                history={history}
                setCurrentUser={setCurrentUser}
              />
            )}
            {currentStep === STEPS_INDEXES.VERIFICATION && (
              <VerificationStep
                currentUser={{ ...currentUser, username }}
                checkIfIsLoggedIn={checkIfIsLoggedIn}
              />
            )}
            {signInUserSession && (
              <EditProfileProvider>
                {currentStep === STEPS_INDEXES.EDIT_PROFILE && (
                  <EditProfileStep
                    goToStep={goToStep}
                  />
                )}
                {currentStep === STEPS_INDEXES.SELECTED_SPORTS && (
                  <SelectSportsStep
                    setCurrentStep={setCurrentStep}
                    setLockedSteps={setLockedSteps}
                    goToStep={goToStep}
                  />
                )}
                {currentStep === STEPS_INDEXES.SELECTED_LEVELS && (
                  <SelectLevelsStep
                    setCurrentStep={setCurrentStep}
                    history={history}
                    goToStep={goToStep}
                  />
                )}
              </EditProfileProvider>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

CreateAccountSteps.defaultProps = {
  history: null,
  authorizedUser: null,
  checkIfIsLoggedIn: null,
};

CreateAccountSteps.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  authorizedUser: PropTypes.oneOfType([
    null,
    PropTypes.shape({
      userConfirmed: PropTypes.bool,
      user: PropTypes.shape({
        username: PropTypes.string,
      }),
    }),
  ]),
  checkIfIsLoggedIn: PropTypes.func,
};

export default CreateAccountSteps;
