export const STEPS_INFO = [
  {
    title: 'Basic info',
    stepIndex: 0,
  },
  {
    title: 'Verification',
    stepIndex: 1,
  },
  {
    title: 'Edit profile',
    stepIndex: 2,
  },
  {
    title: 'Select sports',
    stepIndex: 3,
  },
  {
    title: 'Select level',
    stepIndex: 4,
  },
];

export const STEPS_INDEXES = {
  BASIC_INFO: 0,
  VERIFICATION: 1,
  EDIT_PROFILE: 2,
  SELECTED_SPORTS: 3,
  SELECTED_LEVELS: 4,
};

export const ALL_STEPS = [
  STEPS_INDEXES.BASIC_INFO,
  STEPS_INDEXES.VERIFICATION,
  STEPS_INDEXES.EDIT_PROFILE,
  STEPS_INDEXES.SELECTED_SPORTS,
  STEPS_INDEXES.SELECTED_LEVELS,
];

export const REGISTRATION_STEPS = [
  STEPS_INDEXES.BASIC_INFO,
  STEPS_INDEXES.VERIFICATION,
];

export const SPORTS_STEPS = [
  STEPS_INDEXES.SELECTED_SPORTS,
  STEPS_INDEXES.SELECTED_LEVELS,
];
