import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import PlacesAutocomplete from 'react-places-autocomplete';
import COLORS from 'constants/colors';

const LocationSearchInput = ({
                               address,
                               onChange,
                               onSelect,
                               onClick,
                               allowClear,
                               prefix,
                             }) => {
  return (
    <PlacesAutocomplete
      onChange={onChange}
      onSelect={onSelect}
      value={address}
    >
      {({
          getInputProps, getSuggestionItemProps, suggestions, loading,
        }) => (
          <>
            <Input
            allowClear={allowClear}
            {...getInputProps({
              id: 'address-input',
              className: 'location',
            })}
            placeholder="Location"
            prefix={prefix}
            />

            <div className="autocomplete-dropdown-container">
              {loading ? <div>Loading...</div> : null}
              {suggestions.map((suggestion, index) => {
              const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
              const style = suggestion.active
                ? { backgroundColor: COLORS.alto, cursor: 'pointer' }
                : { backgroundColor: COLORS.white, cursor: 'pointer' };

              const spread = {
                ...getSuggestionItemProps(suggestion, {
                  className,
                  style,
                }),
              };

              return (
                <div
                  {...spread}
                  key={`suggestion-item-${index}`}
                >
                  <div onClick={() => onClick(suggestion.description)}>{suggestion.description}</div>
                </div>
              );
            })}
            </div>
          </>
      )}
    </PlacesAutocomplete>
  );
};

LocationSearchInput.defaultProps = {
  address: '',
  onChange: null,
  onSelect: null,
  onClick: null,
  allowClear: true,
  prefix: null,
};

LocationSearchInput.propTypes = {
  address: PropTypes.string,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  onClick: PropTypes.func,
  allowClear: PropTypes.bool,
  prefix: PropTypes.any,
};

export default LocationSearchInput;
