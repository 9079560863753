export const PROFILE_TYPES = [
  {
    title: 'Student',
    value: 'student',
  },
  {
    title: 'Academic / staff',
    value: 'staff',
  },
  {
    title: 'Other',
    value: 'other',
  },
];

export const DEFAULT_PROFILE_TYPE = PROFILE_TYPES[0].value;
