const DEVELOPMENT = 'development';
const STAGING = 'staging';
const PRODUCTION = 'production';

const ENV = {
  [DEVELOPMENT]: {
    AWS: {
      auth: {
        region: 'eu-west-1',
        userPoolId: 'eu-west-1_FohwwMSjv',
        userPoolWebClientId: '18u4mbnd3ep88gjrsnb4s938pg',
        authenticationFlowType: 'USER_PASSWORD_AUTH',
      },
      appSync: {
        aws_appsync_graphqlEndpoint:
          'https://xsvy2xxq25dthcqoorqdbvxxh4.appsync-api.eu-west-1.amazonaws.com/graphql',
        aws_appsync_region: 'eu-west-1',
        aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
      },
    },
    GOOGLE_MAPS_API_KEY: 'AIzaSyBfJ9IpgzX6hr2NF-L6eURcXb9HVmCKvlY',
  },
  [STAGING]: {},
  [PRODUCTION]: {
    AWS: {
      auth: {
        region: 'eu-west-1',
        userPoolId: 'eu-west-1_20HzeXFaN',
        userPoolWebClientId: '68majga0ulte4tt8tmpismer85',
        authenticationFlowType: 'USER_PASSWORD_AUTH',
      },
      appSync: {
        aws_appsync_graphqlEndpoint:
          'https://gkblmwsbmncznpljuv6g5gelu4.appsync-api.eu-west-1.amazonaws.com/graphql',
        aws_appsync_region: 'eu-west-1',
        aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
      },
    },
    GOOGLE_MAPS_API_KEY: 'AIzaSyBfJ9IpgzX6hr2NF-L6eURcXb9HVmCKvlY',
  },
};

const getEnvVariables = () => {
  if (process.env.REACT_APP_BUILD_ENV === STAGING) {
    return ENV[DEVELOPMENT];
  }

  if (process.env.REACT_APP_BUILD_ENV === PRODUCTION) {
    return ENV[PRODUCTION];
  }

  return ENV[DEVELOPMENT];
};

export default getEnvVariables();
