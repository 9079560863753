import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Button,
} from 'antd';
import Coach from './Coach';
import styles from './index.module.scss';

const Sport = ({
  sport: {
    description,
    coach,
  } = {},
  showDateSelector,
}) => {
  return (
    <div className={styles.sportInfo}>
      <Row type="flex">
        {coach &&
          <Col span={24}>
            <Row align="middle">
              <Col span={16}>
                <Coach coach={coach} />
              </Col>
              <Col span={6}>
                <Button
                  onClick={showDateSelector}
                  type="primary"
                >
                  Book
                </Button>
              </Col>
            </Row>
          </Col>}
        <Col span={24}>
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </Col>
        <Col span={24} align="center">
          <Button
            onClick={showDateSelector}
            style={{marginTop: 10}}
            type="primary"
          >Book</Button>
        </Col>
      </Row>
    </div>
  );
};

Sport.defaultProps = {
  sport: null,
  showDateSelector: null,
};

Sport.propTypes = {
  sport: PropTypes.shape({
    description: PropTypes.string,
    coach: PropTypes.shape({
      sports: PropTypes.arrayOf(
        PropTypes.shape({
          sport: PropTypes.shape({
            displayName: PropTypes.string,
          }),
        }),
      ),
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      profilePic: PropTypes.oneOfType([
        null,
        PropTypes.string,
      ]),
    }),
  }),
  showDateSelector: PropTypes.func,
};

export default Sport;
