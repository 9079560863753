import React from 'react';
import { Row, Col } from 'antd';

const DeleteSportModal = () => {
  return (
    <Row justify="center" type="flex">
      <Col align="center" col={24}>
        <h4>Are you sure you want to delete?</h4>
      </Col>
      <Col align="center" col={24}>
        <img
          alt="Delete"
          src="/delete.svg"
          style={{
           width: '300px',
            height: '300px',
            textAlign: 'center',
            margin: '30px 0 50px',
          }}
        />
      </Col>
    </Row>
  );
};

export default DeleteSportModal;
