import React, {
  useState, useCallback, useContext, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Input,
  Button,
} from 'antd';
import classNames from 'classnames';
import pick from 'lodash/pick';
import { SearchOutlined, UserOutlined } from '@ant-design/icons';
import { EditProfileContext } from 'contextProviders/EditProfileProvider';
import {
  STEPS_INDEXES, REGISTRATION_STEPS,
} from 'constants/registrationSteps';
import { DEFAULT_SPORT_LEVEL } from 'constants/sports';
import { ProfileContext } from 'contextProviders/ProfileProvider';
import styles from '../index.module.scss';

const SelectSports = ({ goToStep, setLockedSteps, isModal }) => {
  const {
    fetchedSports: { listSports: { sports = [] } = {} } = {},
    selectedSports,
    setSelectedSports,
  } = useContext(EditProfileContext);

  const {
    fetchedMyProfile: {
      getMyProfile: { sports: profileSports = [] } = {},
    } = {},
  } = useContext(ProfileContext);

  // const [isLoading, setLoading] = useState(false);
  const [filteredSports, setFilteredSports] = useState([]);

  useEffect(() => {
    setSelectedSports(profileSports);
  }, [profileSports, setSelectedSports]);

  useEffect(() => {
    if (selectedSports.length > 0) {
      setSelectedSports(selectedSports);
    }
  }, [selectedSports, setSelectedSports]);

  useEffect(() => setFilteredSports(sports), [sports]);

  const handleSearch = useCallback(e => {
    const { value = '' } = e?.target || {};
    if (!value) {
      setFilteredSports(sports);
    } else {
      const search = sports.filter(i => i.name
        .toLocaleLowerCase()
        .indexOf(value.toLocaleLowerCase()) !== -1);
      setFilteredSports(search);
    }
  }, [sports]);

  const handleSelectSport = useCallback(sport => {
    const selected = selectedSports.some(item => item?.sport?.name === sport?.name)
      ? selectedSports.filter(item => item?.sport?.name !== sport?.name)
      : [...selectedSports, {
        sport: pick(sport, ['name', 'displayName']),
        level: DEFAULT_SPORT_LEVEL,
      }];
    setSelectedSports(selected);
  }, [selectedSports, setSelectedSports]);

  useEffect(() => setLockedSteps(selectedSports.length > 0
    ? REGISTRATION_STEPS
    : [...REGISTRATION_STEPS, STEPS_INDEXES.SELECTED_LEVELS]), [selectedSports, setLockedSteps]);

  const handleClickNext = () => selectedSports.length > 0 && goToStep(STEPS_INDEXES.SELECTED_LEVELS);

  const handleClickBack = () => goToStep(STEPS_INDEXES.EDIT_PROFILE);

  if (sports.length === 0) return null;

  return (
    <Row justify="center">
      <Col span={24}>
        <h1 className={styles.loginH1}>Select your sports</h1>

        <div style={{ padding: '0 0 20px' }}>Select one or several sports that you practice in general. This is not related to your bookings</div>

        <Input
          placeholder="Search a sport"
          prefix={<SearchOutlined />}
          onChange={handleSearch}
        />

        <div className={styles.sportWrapper}>
          <Row gutter={[22, 28]}>
            {filteredSports.map(sport => (
              <Col
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 6 }}
                xl={{ span: 6 }}
                key={sport.name}
              >
                <div
                  className={classNames({
                    [styles.sport]: true,
                    [styles.sportSelected]: selectedSports.some(item => item?.sport?.name === sport?.name),
                  })}
                  onClick={() => handleSelectSport(sport)}
                  style={{
                    '--color': '#949494',
                    '--image': `url(${sport.imageUrl})`,
                    '--image2': `url(${sport.imageUrl})`,
                    '--blend-top': 'multiply',
                    '--blend-bottom': 'multiply',
                    '--color-v': 'transparent',
                  }}
                >
                  <div className={styles.sportTitle}>{sport.displayName}</div>
                  <div className={styles.sportCount}>
                    <UserOutlined className={styles.sportIcon} />
                    {sport.count}
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>

        {isModal && (
        <Row>
          <Col span={12} offset={6}>
            <Button
              onClick={handleClickNext}
              style={{ width: '100%' }}
              disabled={selectedSports.length === 0}
              type="primary"
            >
              Next
            </Button>
          </Col>
        </Row>
        )}

        {!isModal && (
        <Row>
          <Col span={12}>
            <Button
              // disabled={isLoading}
              // loading={isLoading}
              onClick={handleClickBack}
            >
              Back
            </Button>
          </Col>
          <Col span={12} align="right">
            <Button
              type="primary"
              disabled={selectedSports.length === 0}
              // loading={isLoading}
              onClick={handleClickNext}
            >
              Next
            </Button>
          </Col>
        </Row>
        )}
      </Col>
    </Row>
  );
};

SelectSports.defaultProps = {
  goToStep: null,
  setLockedSteps: null,
  isModal: false,
};

SelectSports.propTypes = {
  goToStep: PropTypes.func,
  setLockedSteps: PropTypes.func,
  isModal: PropTypes.bool,
};

export default SelectSports;
