import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Form,
  Input,
  Row,
  Col,
} from 'antd';
import { Auth } from 'aws-amplify';
import { MailOutlined } from '@ant-design/icons';
import ErrorText from 'components/common/ErrorText';
import { ROUTE_RESET_PASSWORD } from 'constants/routes';
import styles from './index.module.scss';

const ForgotPassword = ({ history }) => {
  const [isLoading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const handleForgotPassword = useCallback(async ({ email: userEmail }) => {
    try {
      setLoading(true);
      const username = userEmail.toLowerCase();
      const data = await Auth.forgotPassword(username);

      if (data.CodeDeliveryDetails) {
        setLoading(false);
        setEmail(username);
      } else {
        setError('Something goes wrong. Please try again later.');
      }
    } catch (e) {
      setLoading(false);
      setError(e.message);
    }
  }, []);

  const handleResetPassword = useCallback(e => {
    e.preventDefault();
    history.push(`${ROUTE_RESET_PASSWORD}/${email}`);
  }, [history, email]);

  return (
    <Row className={styles.wrapper}>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 20 }} lg={{ span: 18 }} xl={{ span: 20 }}>
        <h1 className={styles.loginH1}>Forgot password</h1>

        {email && (
          <div className={styles.resetPassword}>
            Your password recovery email was sent to you<br />
            (Please check your spam folder too)<br /><br /><br />
            <a href="/" onClick={handleResetPassword}>Reset your password</a>
          </div>
        )}

        {!email && (
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={handleForgotPassword}
          >
            <div className={styles.resetPassword}>Please enter your email to reset your password</div>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Please input your email!',
                },
              ]}
            >
              <Input
                allowClear
                placeholder="Email"
                prefix={<MailOutlined />}
              />
            </Form.Item>

            <Button
              type="primary"
              htmlType="submit"
              disabled={isLoading}
              loading={isLoading}
            >
              Send recovery link
            </Button>

            <ErrorText text={error} />
          </Form>
        )}
      </Col>
    </Row>
  );
};

ForgotPassword.defaultProps = {
  history: {},
};

ForgotPassword.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default ForgotPassword;
