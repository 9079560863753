import React from 'react';
import { Layout } from 'antd';
import PropTypes from 'prop-types';
import Profile from './Profile';
import Sports from './Sports';

const { Content } = Layout;

const MyProfile = ({ history }) => (
  <Content>
    <div className="site-layout-background">
      <Profile history={history} />
      <br />
      <br />
      <br />
      <Sports />
    </div>
  </Content>
);

MyProfile.defaultProps = {
  history: null,
};

MyProfile.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default MyProfile;
