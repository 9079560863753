import React, { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import QRCode from 'react-qr-code';
import moment from 'moment';
import { ClockCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import classNames from 'classnames';
import { ModalContext } from 'contextProviders/ModalProvider';
import { ProfileContext } from 'contextProviders/ProfileProvider';
import EditProfileTypeModal from 'components/Dashboard/MyProfile/Profile/EditProfileTypeModal';
import styles from '../University/index.module.scss';

const SportClass = ({
  sportClass: {
    classId,
    date,
    endTime,
    maxParticipants,
    name,
    participantsCount,
    sportName,
    startTime,
  } = {},
  unBookClass,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [editProfileTypeVisible, setEditProfileTypeVisible] = useState(false);
  const {
    fetchedMyProfile: { getMyProfile: { id: userId, profileType } = {} } = {},
  } = useContext(ProfileContext);

  const { openModal, toggleModal } = useContext(ModalContext);

  const handleUnBook = useCallback(async () => {
    setIsLoading(true);
    await unBookClass(classId);
    setIsLoading(false);
    window.location.reload();
  }, [classId, unBookClass]);

  const handleDownloadQrCode = () => {
    const svg = document.getElementById('QRCode');
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.download = 'QRCode';
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  const footer = [
    <Button key="back" onClick={() => toggleModal(false)}>
      Cancel
    </Button>,
    <Button key="download" onClick={handleDownloadQrCode}>
      Download
    </Button>,
  ];

  const [hr, min] = startTime?.split(':');
  const dateMoment = moment(date, 'YYYY-MM-DD')
    .startOf('day')
    .add(hr, 'hours')
    .add(min, 'minutes');
  const isAfter = dateMoment.clone().isAfter(moment());

  const qrCodeValue = JSON.stringify({ classId, userId });

  const handleEditProfileTypeModal = useCallback(
    () => setEditProfileTypeVisible(true),
    [setEditProfileTypeVisible],
  );

  const handleOpenQrCodeModal = () => {
    if (profileType) {
      openModal({
        modalContent: (
          <div className={styles.qrCodeWrapper}>
            <QRCode value={qrCodeValue} id="QRCode" />
          </div>
        ),
        footer,
      });
    } else {
      handleEditProfileTypeModal();
    }
  };

  const handleCloseEditProfileTypeModal = () => {
    setEditProfileTypeVisible(false);
    openModal({
      modalContent: (
        <div className={styles.qrCodeWrapper}>
          <QRCode value={qrCodeValue} id="QRCode" />
        </div>
      ),
      footer,
    });
  };

  return (
    <>
      <div className={styles.sportInfo}>
        <h4>
          {sportName} - {name}:
        </h4>
        <div className={styles.rowWrapper}>
          <div
            className={classNames({
              [styles.sportDate]: true,
              [styles.sportDateFuture]: isAfter,
              [styles.sportDatePast]: !isAfter,
            })}
          >
            <ClockCircleOutlined /> {moment(date).format('dddd DD/MM/YYYY')}
          </div>
          <Button onClick={handleOpenQrCodeModal}>Download QR Code</Button>
        </div>
        <div className={styles.sportTimeBox}>
          <div>
            <div className={styles.sportTime}>
              Time {startTime.substring(0, 5)} - {endTime.substring(0, 5)}
            </div>
            <div className={styles.participants}>
              {participantsCount}/{maxParticipants} participants
            </div>
          </div>
          <div className={styles.rowWrapper}>
            {isAfter && (
              <Button
                onClick={handleUnBook}
                disabled={isLoading}
                loading={isLoading}
              >
                UnBook
              </Button>
            )}
          </div>
        </div>
      </div>
      {editProfileTypeVisible && (
        <EditProfileTypeModal hideModal={handleCloseEditProfileTypeModal} />
      )}
    </>
  );
};

SportClass.defaultProps = {
  sportClass: null,
  unBookClass: null,
};

SportClass.propTypes = {
  unBookClass: PropTypes.func,
  sportClass: PropTypes.shape({
    classId: PropTypes.string,
    date: PropTypes.string,
    endTime: PropTypes.string,
    isBooked: PropTypes.bool,
    maxParticipants: PropTypes.number,
    name: PropTypes.string,
    participantsCount: PropTypes.number,
    sportName: PropTypes.string,
    startTime: PropTypes.string,
  }),
};

export default SportClass;
