import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

export const ModalContext = React.createContext();

const ModalProvider = ({ children }) => {
  const modalRef = useRef();
  const [context, setContext] = useState();
  const [modalOptions, setModalOptions] = useState({ isOpened: false });

  useEffect(() => {
    setContext(modalRef.current);
  }, [setContext]);

  const updaters = {
    toggleModal: (isOpen) => {
      setModalOptions({ isOpened: isOpen });
    },
    openModal: ({
     modalContent,
     onOkClicked,
     footer,
     width,
    }) => {
      setModalOptions({
        isOpened: true,
        content: modalContent,
        handleOkClicked: onOkClicked,
        footer,
        width,
      });
    },
  };

  return (
    <>
      <ModalContext.Provider
        value={{
          ...updaters,
          ...modalOptions,
          context,
        }}
      >
        {children}
      </ModalContext.Provider>
      <div ref={modalRef} />
    </>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ModalProvider;
