import React, {
  useState, useCallback, useEffect, useContext,
} from 'react';
import { graphql } from 'react-apollo';
import PropTypes from 'prop-types';
import {
  Button,
  Form,
  Row,
  Col,
  Input,
  Modal,
  Select,
} from 'antd';
import moment from 'moment';
import pick from 'lodash/pick';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { UserOutlined, HomeOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { GET_UNIVERSITIES_LIST_FOR_CREATE_ACCOUNT } from 'api/queries';
import { calculateDistance } from 'helpers/distance';
import { ProfileContext } from 'contextProviders/ProfileProvider';
import LocationSearchInput from 'components/common/LocationSearchInput';

const DEFAULT_LOCATION = { latitude: null, longitude: null, name: '' };

const EditProfile = ({
 hideModal, universitiesList: {
  listUniversities: {
    universities = [],
  } = {},
} = {},
}) => {
  const {
    fetchedMyProfile,
    fetchedMyProfile: {
      getMyProfile,
    },
    updateUserProfile,
  } = useContext(ProfileContext);

  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  // const [error, setError] = useState(false);
  const [location, setLocation] = useState(DEFAULT_LOCATION);
  const [university, setUniversity] = useState('');
  const [universityOptions, setUniversityOptions] = useState([]);

  useEffect(() => {
    if (universities.length > 0) {
      const options = universities
        .map(curUniversity => {
          const distance = location?.latitude
          && location?.longitude
          && curUniversity?.location?.latitude
          && curUniversity?.location?.longitude
            ? calculateDistance(
              location?.latitude,
              location?.longitude,
              curUniversity?.location?.latitude,
              curUniversity?.location?.longitude,
            ) : 0;

          return { ...curUniversity, value: curUniversity?.name, distance };
        })
        .sort((a, b) => {
          return parseFloat(a.distance) - parseFloat(b.distance);
        });

      const uOptions = options.map(u => ({ value: u.name, label: u.centerName }));
      setUniversityOptions(uOptions);
    }
  }, [universities, location]);

  const handleChangeLocation = useCallback(name => {
    if (name) {
      setLocation({ ...location, name });
    } else {
      setLocation(DEFAULT_LOCATION);
    }
  }, [setLocation, location]);

  const handleFilterUniversities = useCallback(
    (inputValue, option) => option?.label?.toLowerCase().indexOf(inputValue?.toLowerCase()) !== -1, [],
  );

  const handleAddressSelect = useCallback(async name => {
    try {
      setLocation({ ...location, name });
      const results = await geocodeByAddress(name);
      const { lat: latitude, lng: longitude } = await getLatLng(results[0]);
      setLocation({ latitude, longitude, name });
    } catch (e) {
      console.error('Error', e);
    }
  }, [location, setLocation]);

  const handleEditProfile = useCallback(async () => {
    try {
      await form.validateFields();
      let variables = form.getFieldsValue();
      variables = { ...variables, location, university };
      setLoading(true);
      await updateUserProfile({ variables });
      await fetchedMyProfile.refetch();
      setLoading(false);
      hideModal();
    } catch (e) {
      setLoading(false);
    }
  }, [location, university, fetchedMyProfile, form, hideModal, updateUserProfile]);

  useEffect(() => {
    form.setFieldsValue({
      ...pick(getMyProfile, [
        'firstName',
        'lastName',
        'gender',
      ]),
      dob: moment(getMyProfile?.dob, 'YYYY-MM-DD'),
    });
    setLocation(pick(getMyProfile.location, [
      'name',
      'latitude',
      'longitude',
    ]));
    setUniversity(getMyProfile?.university?.name);
  }, [getMyProfile, form]);

  return (
    <Modal
      closable={false}
      visible
      footer={[
        <Button
          key="back"
          onClick={hideModal}
          disabled={isLoading}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isLoading}
          disabled={isLoading}
          onClick={handleEditProfile}
        >
          Save
        </Button>,
      ]}
    >
      <Row justify="center" type="flex">
        <Col span={24}>
          <h1
            style={{
              margin: '0 auto 16px',
              textAlign: 'center',
            }}
          >Edit</h1>
        </Col>
        <Col span={24}>
          <Form
            form={form}
            name="basic"
            initialValues={{
              location: DEFAULT_LOCATION,
            }}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      type: 'string',
                      message: 'Please input your first name!',
                    },
                  ]}
                >
                  <Input
                    allowClear
                    placeholder="First name"
                    prefix={<UserOutlined />}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      type: 'string',
                      message: 'Please input your last name!',
                    },
                  ]}
                >
                  <Input
                    allowClear
                    placeholder="Last name"
                    prefix={<UserOutlined />}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="location"
                  value={location.name}
                  rules={[
                    () => ({
                      validator(rule, value) {
                        if (!value) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise.reject('Please provide your location');
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <LocationSearchInput
                    allowClear
                    prefix={<EnvironmentOutlined />}
                    address={location.name}
                    clearAddress={e => {
                      console.log(e);
                    }}
                    onChange={handleChangeLocation}
                    onClick={handleAddressSelect}
                    onSelect={handleAddressSelect}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="university"
                  value={university}
                  className="withPrefix"
                  rules={[
                    () => ({
                      validator() {
                        if (!university) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise.reject('Please provide the name of university or college');
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    value={university}
                    options={universityOptions}
                    onChange={value => setUniversity(value)}
                    placeholder="University/College"
                    filterOption={handleFilterUniversities}
                  />
                  <HomeOutlined />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

EditProfile.defaultProps = {
  hideModal: null,
};

EditProfile.propTypes = {
  hideModal: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  universitiesList: PropTypes.object,
};

const withGetUniversities = graphql(GET_UNIVERSITIES_LIST_FOR_CREATE_ACCOUNT, {
  name: 'universitiesList',
});

export default withGetUniversities(EditProfile);
