import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import {
  GET_MY_PROFILE,
} from 'api/queries';
import {
  CREATE_USER_PROFILE,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_TYPE,
  SET_MY_UNIVERSITY_PROFILE_TYPE,
} from 'api/mutations';

export const ProfileContext = React.createContext();

const ProfileProvider = ({
   children,
   fetchedMyProfile,
   createUserProfile,
   updateUserProfile,
   updateUserProfileType,
   updateMyUniversityProfileType,
 }) => {
  const updaters = {
    fetchedMyProfile,
    createUserProfile,
    updateUserProfile,
    updateUserProfileType,
    updateMyUniversityProfileType,
  };

  return (
    <>
      <ProfileContext.Provider
        value={{
          ...updaters,
        }}
      >
        {children}
      </ProfileContext.Provider>
    </>
  );
};

const withGetMyProfile = graphql(GET_MY_PROFILE, {
  name: 'fetchedMyProfile',
});

const withCreateUserProfile = graphql(CREATE_USER_PROFILE, {
  name: 'createUserProfile',
});

const withUpdateUserProfile = graphql(UPDATE_USER_PROFILE, {
  name: 'updateUserProfile',
});

const withUpdateUserProfileType = graphql(UPDATE_USER_PROFILE_TYPE, {
  name: 'updateUserProfileType',
});

const withUpdateMyUniversityProfileType = graphql(SET_MY_UNIVERSITY_PROFILE_TYPE, {
  name: 'updateMyUniversityProfileType',
});

const withGraphQl = compose(
  withGetMyProfile,
  withCreateUserProfile,
  withUpdateUserProfile,
  withUpdateUserProfileType,
  withUpdateMyUniversityProfileType,
);

ProfileProvider.defaultProps = {
  children: null,
  fetchedMyProfile: null,
  createUserProfile: null,
  updateUserProfile: null,
  updateUserProfileType: null,
  updateMyUniversityProfileType: null,
  // fetchedMyUniversityProfileType: null,
};

ProfileProvider.propTypes = {
  children: PropTypes.any,
  fetchedMyProfile: PropTypes.shape(),
  createUserProfile: PropTypes.func,
  updateUserProfile: PropTypes.func,
  updateUserProfileType: PropTypes.func,
  updateMyUniversityProfileType: PropTypes.func,
};

export default withGraphQl(ProfileProvider);
