import React, { useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Radio, Button, Modal,
} from 'antd';
import { PROFILE_TYPES, DEFAULT_PROFILE_TYPE } from 'constants/profile';
import { ROUTE_BOOKING } from 'constants/routes';
import { ProfileContext } from 'contextProviders/ProfileProvider';
import styles from './index.module.scss';

const EditProfileTypeModal = ({ history, hideModal }) => {
  const { fetchedMyProfile, updateUserProfileType } = useContext(ProfileContext);

  const [profileType, setProfileType] = useState(DEFAULT_PROFILE_TYPE);
  const [isLoading, setIsLoading] = useState(false);

  const onChange = useCallback(
    (e) => setProfileType(e?.target?.value),
    [setProfileType],
  );

  const handleEditProfileType = useCallback(async () => {
    setIsLoading(true);
    const variables = { profileType };
    await updateUserProfileType({ variables });
    await fetchedMyProfile.refetch();
    setIsLoading(false);
    hideModal();
    if (history) {
      history.push(ROUTE_BOOKING);
    }
  }, [profileType, fetchedMyProfile, hideModal, updateUserProfileType]);

  return (
    <Modal closable={false} visible footer={[]}>
      <Row justify="center" type="flex">
        <Col align="center" span={24}>
          <h2>QR Code</h2>
          <div className={styles.subtitle}>
            We ask this for statistical purposes only
          </div>
          <div className={styles.subtitle}>Which category are you?</div>
          <div>
            <Radio.Group onChange={onChange} value={profileType}>
              {PROFILE_TYPES.map((type) => (
                <Radio key={type.value} value={type.value}>
                  {type.title}
                </Radio>
              ))}
            </Radio.Group>
          </div>
        </Col>
        <Col align="center" span={24}>
          <div className={styles.subtitle}>
            <Button
              key="submit"
              type="primary"
              loading={isLoading}
              disabled={isLoading}
              onClick={handleEditProfileType}
            >
              Next
            </Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

EditProfileTypeModal.defaultProps = {
  history: null,
  hideModal: null,
};

EditProfileTypeModal.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  hideModal: PropTypes.func,
};

export default EditProfileTypeModal;
