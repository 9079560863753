import React from 'react';
import {
  Layout,
} from 'antd';
import MySportClasses from './MySportClasses';

const {
  Content,
} = Layout;

const Booking = () => {
  return (
    <Content>
      <div className="site-layout-background">
        <MySportClasses />
      </div>
    </Content>
  );
};

export default Booking;
