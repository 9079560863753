import React, {
  useCallback, useContext,
} from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Row, Col, Button } from 'antd';
import { ProfileContext } from 'contextProviders/ProfileProvider';
import { EditProfileContext } from 'contextProviders/EditProfileProvider';
import { ModalContext } from 'contextProviders/ModalProvider';
import Sport from './Sport';
import DeleteSportModal from './DeleteSportModal';
import AddSportsModal from './AddSportsModal';

const Sports = () => {
  const {
    fetchedMyProfile,
    fetchedMyProfile: {
      getMyProfile = {},
    } = {},
  } = useContext(ProfileContext);
  const {
    updateUserSports,
    fetchedSports: { listSports = {} } = {},
  } = useContext(EditProfileContext);
  const {
    openModal,
    toggleModal,
  } = useContext(ModalContext);

  const profileSports = getMyProfile?.sports?.map(profileSport => ({
    level: profileSport?.level,
    name: profileSport?.sport?.name,
    displayName: profileSport?.sport?.displayName,
  })) || [];

  const selectedSport = listSports?.sports?.filter(sport => profileSports
    .some(profileSport => profileSport.name === sport.name)).map(sport => ({
    ...sport,
    ...profileSports
      .find(profileSport => profileSport.name === sport.name),
  })) || [];

  const handleClickAddSports = useCallback(() => {
    openModal({
      modalContent: <AddSportsModal toggleModal={toggleModal} />,
      footer: [],
      width: 1024,
    });
  }, [openModal, toggleModal]);

  const handleChange = useCallback(async (level, name) => {
    const sports = getMyProfile?.sports?.map(item => ({
      sport: item?.sport?.name,
      level: item?.sport?.name === name
        ? level
        : item?.level,
    }));
    const variables = { sports };
    await updateUserSports({ variables });
    await fetchedMyProfile.refetch();
  }, [fetchedMyProfile, getMyProfile.sports, updateUserSports]);

  const onDelete = useCallback(async name => {
    toggleModal(false);
    const sports = getMyProfile?.sports?.filter(item => item?.sport?.name !== name).map(item => ({
      sport: item?.sport?.name,
      level: item?.level,
    }));
    const variables = { sports };
    await updateUserSports({ variables });
    await fetchedMyProfile.refetch();
  }, [getMyProfile, fetchedMyProfile, toggleModal, updateUserSports]);

  const handleDelete = useCallback(async name => {
    const footer = [
      <Button key="back" onClick={() => toggleModal(false)}>
        Cancel
      </Button>,
      <Button key="back" type="primary" onClick={() => onDelete(name)}>
        Yes
      </Button>,
    ];
    openModal({
      modalContent: <DeleteSportModal />,
      footer,
    });
  }, [onDelete, openModal, toggleModal]);

  return (
    <div className="sports-layout">
      <Row gutter={[30, 30]}>
        <Col span={12}>
          <h2>Sports</h2>
        </Col>
        <Col span={12}>
          <Button
            type="primary"
            onClick={handleClickAddSports}
            style={{
              height: '36px',
              width: '144px',
              marginLeft: 'auto',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              fontWeight: 300,
            }}
          >
            <PlusOutlined /> Add sport
          </Button>
        </Col>
        {selectedSport.map(sport => (
          <Col span={12} key={sport.name}>
            <Sport
              sport={sport}
              onChange={value => handleChange(value, sport.name)}
              onDelete={() => handleDelete(sport.name)}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Sports;
