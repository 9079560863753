const COLORS = {
  darkgray: '#A9A9A9',
  silkBlue: '#488AC7',
  silverChalice: '#ADADAD',
  diaryCream: '#FFE4BD',
  safetyOrange: '#FF9700',
  downy: '#65C6C4',
  black: '#000',
  mirage: '#151E29',
  rhino: '#3D4651',
  nevada: '#696F77',
  graySuit: '#94989E',
  silverSand: '#BFC1C5',
  alto: '#D9DBDD',
  athensGray: '#EFF0F2',
  blackHaze: '#F6F6F7',
  romance: '#FCFCFD',
  white: '#fff',
  steel: '#666666',
  zircon: '#E0E0E0',
  magnesium: '#B2B2B2',
  mountainMist: '#949494',
};

export default COLORS;
