import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import {
  Button,
  Form,
  Input,
  Row,
  Col,
} from 'antd';
import ErrorText from 'components/common/ErrorText';
import styles from '../index.module.scss';

const Verification = ({
  currentUser,
  currentUser: {
    username,
    password,
  },
  checkIfIsLoggedIn,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleVerify = useCallback(async ({
    code0,
    code1,
    code2,
    code3,
    code4,
    code5,
  }) => {
    try {
      setLoading(true);
      const code = `${code0}${code1}${code2}${code3}${code4}${code5}`;
      await Auth.confirmSignUp(username, code);
      await Auth.signIn(username, password);
      await checkIfIsLoggedIn();
      setLoading(false);
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  }, [checkIfIsLoggedIn, password, username]);

  const handleResendVerificationCode = useCallback(e => e.preventDefault(), []);

  return (
    <Row justify="center">
      <Col span={24}>
        <h1 className={styles.loginH1}>Hi, {username}!</h1>
        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={handleVerify}
        >
          <Row gutter={[20, 8]} justify="space-around" style={{ margin: 0 }}>
            <Col span={24} align="center">
              <div className={styles.checkEmail}>Check your email <span className="primary">{username}</span></div>
              <div className={styles.checkVerificationCode}>We have sent you a verification code</div>
            </Col>

            <Col span={4} align="center">
              <Form.Item
                className={styles.code}
                name="code0"
                type="number"
                rules={[{ required: true, message: ' ', max: 1 }]}
              >
                <Input placeholder="" />
              </Form.Item>
            </Col>
            <Col span={4} align="center">
              <Form.Item
                className={styles.code}
                name="code1"
                rules={[{ required: true, message: ' ', max: 1 }]}
              >
                <Input placeholder="" />
              </Form.Item>
            </Col>
            <Col span={4} align="center">
              <Form.Item
                className={styles.code}
                name="code2"
                rules={[{ required: true, message: ' ', max: 1 }]}
              >
                <Input placeholder="" />
              </Form.Item>
            </Col>
            <Col span={4} align="center">
              <Form.Item
                className={styles.code}
                name="code3"
                rules={[{ required: true, message: ' ', max: 1 }]}
              >
                <Input placeholder="" />
              </Form.Item>
            </Col>
            <Col span={4} align="center">
              <Form.Item
                className={styles.code}
                name="code4"
                rules={[{ required: true, message: ' ', max: 1 }]}
              >
                <Input placeholder="" />
              </Form.Item>
            </Col>
            <Col span={4} align="center">
              <Form.Item
                className={styles.code}
                name="code5"
                rules={[{ required: true, message: ' ', max: 1 }]}
              >
                <Input placeholder="" />
              </Form.Item>
            </Col>
          </Row>

          <Col span={24} align="center">
            <hr />
            <div className={styles.didNotGetEmail}>Didn't get an email from us?</div>
            <div className={styles.resendVerification}>Check your spam folder or <a
href="/"
              onClick={handleResendVerificationCode}
            >resend verification code</a></div>
          </Col>

          <Row>
            <Col offset={6} span={12} align="center">
              <Button
                type="primary"
                htmlType="submit"
                disabled={isLoading}
                loading={isLoading}
              >
                Next
              </Button>
            </Col>
            <ErrorText text={error} />
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

Verification.defaultProps = {
  currentUser: {},
  checkIfIsLoggedIn: null,
};

Verification.propTypes = {
  currentUser: PropTypes.shape({
    username: PropTypes.string,
    password: PropTypes.string,
  }),
  checkIfIsLoggedIn: PropTypes.func,
};

export default Verification;
