import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

const Logo = ({ onClick, style = {} }) => {
  return (
    <img
      alt="logo"
      src="/logo.svg"
      className={styles.logo}
      onClick={onClick}
      style={{ ...style, cursor: onClick ? 'pointer' : 'default' }}
    />
  );
};

Logo.defaultProps = {
  style: null,
  onClick: null,
};

Logo.propTypes = {
  style: PropTypes.shape({}),
  onClick: PropTypes.func,
};

export default Logo;
