export const SPORT_LEVEL_EMPTY = 'empty';
export const SPORT_LEVEL_BEGINNER = 'beginner';
export const SPORT_LEVEL_INTERMEDIATE = 'intermediate';
export const SPORT_LEVEL_ADVANCED = 'advanced';

export const DEFAULT_SPORT_LEVEL = SPORT_LEVEL_BEGINNER;

export const SPORTS_LEVELS = {
  [SPORT_LEVEL_BEGINNER]: 0,
  [SPORT_LEVEL_INTERMEDIATE]: 50,
  [SPORT_LEVEL_ADVANCED]: 100,
};

export const PROFILE_SPORTS_LEVEL_SLIDER = {
  [SPORTS_LEVELS[SPORT_LEVEL_BEGINNER]]: {
    style: {
      transform: 'translateX(-20px)',
    },
    label: SPORT_LEVEL_BEGINNER,
  },
  [SPORTS_LEVELS[SPORT_LEVEL_INTERMEDIATE]]: SPORT_LEVEL_INTERMEDIATE,
  [SPORTS_LEVELS[SPORT_LEVEL_ADVANCED]]: {
    style: {
      transform: 'translateX(-100%)',
      left: 'calc(100% + 20px)',
    },
    label: SPORT_LEVEL_ADVANCED,
  },
};

export const DASHBOARD_SPORTS_LEVELS = {
  [SPORT_LEVEL_EMPTY]: 0,
  [SPORT_LEVEL_BEGINNER]: 33,
  [SPORT_LEVEL_INTERMEDIATE]: 66,
  [SPORT_LEVEL_ADVANCED]: 100,
};

export const DASHBOARD_SPORTS_LEVEL_SLIDER = {
  [DASHBOARD_SPORTS_LEVELS[SPORT_LEVEL_EMPTY]]: '',
  [DASHBOARD_SPORTS_LEVELS[SPORT_LEVEL_BEGINNER]]: '',
  [DASHBOARD_SPORTS_LEVELS[SPORT_LEVEL_INTERMEDIATE]]: '',
  [DASHBOARD_SPORTS_LEVELS[SPORT_LEVEL_ADVANCED]]: '',
};
