import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { GET_SPORTS_LIST } from 'api/queries';
import {
  UPDATE_USER_SPORTS,
  UPDATE_PROFILE_PHOTO,
} from 'api/mutations';

export const EditProfileContext = React.createContext();

const EditProfileProvider = ({
 children,
 fetchedSports,
 fetchedMyProfile,
 createUserProfile,
 updateUserSports,
 updateProfilePhoto,
}) => {
  const [selectedSports, setSelectedSports] = useState([]);
  const [sportsLevels, setSportsLevels] = useState({});

  const updaters = {
    fetchedSports,
    selectedSports,
    setSelectedSports,
    sportsLevels,
    setSportsLevels,
    fetchedMyProfile,
    createUserProfile,
    updateUserSports,
    updateProfilePhoto,
  };

  return (
    <>
      <EditProfileContext.Provider
        value={{
          ...updaters,
        }}
      >
        {children}
      </EditProfileContext.Provider>
    </>
  );
};

const withGetSports = graphql(GET_SPORTS_LIST, {
  name: 'fetchedSports',
});

const withUpdateUserSports = graphql(UPDATE_USER_SPORTS, {
  name: 'updateUserSports',
});

const withUpdateProfilePhoto = graphql(UPDATE_PROFILE_PHOTO, {
  name: 'updateProfilePhoto',
});

const withGraphQl = compose(
  withGetSports,
  withUpdateUserSports,
  withUpdateProfilePhoto,
);

EditProfileProvider.defaultProps = {
  children: null,
  fetchedSports: null,
  fetchedMyProfile: null,
  createUserProfile: null,
  updateUserSports: null,
  updateProfilePhoto: null,
};

EditProfileProvider.propTypes = {
  children: PropTypes.any,
  fetchedSports: PropTypes.shape(),
  fetchedMyProfile: PropTypes.shape(),
  createUserProfile: PropTypes.func,
  updateUserSports: PropTypes.func,
  updateProfilePhoto: PropTypes.func,
};

export default withGraphQl(EditProfileProvider);
