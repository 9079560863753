import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { Modal as ModalAnt, Button } from 'antd';
import { ModalContext } from 'contextProviders/ModalProvider';

const Modal = () => {
  const {
    context,
    isOpened,
    toggleModal,
    content,
    handleOkClicked,
    footer,
    width,
  } = useContext(
    ModalContext,
  );

  if (!isOpened) {
    return null;
  }

  const handleModalClose = () => {
    toggleModal(false);
  };

  const defaultFooter = [
    <Button key="back" onClick={handleModalClose}>
      Cancel
    </Button>,
  ];

  if (handleOkClicked) {
    defaultFooter.push(
      <Button key="submit" type="primary" onClick={handleOkClicked}>
        Ok
      </Button>,
    );
  }

  return context
    ? ReactDOM.createPortal(
      <ModalAnt
        ModalAnt="Basic Modal"
        visible={isOpened}
        // closable={false}
        className={!handleOkClicked ? 'only-cancel' : null}
        onCancel={handleModalClose}
        // onOk={handleOkClicked}
        footer={footer || defaultFooter}
        width={width}
      >
        {content}
      </ModalAnt>,
      context,
    )
    : null;
};

export default Modal;
