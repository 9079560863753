import React from 'react';
import PropTypes from 'prop-types';
import {
  Slider,
} from 'antd';
import classNames from 'classnames';
import { PROFILE_SPORTS_LEVEL_SLIDER, SPORTS_LEVELS } from 'constants/sports';
import styles from '../index.module.scss';

const SetLevel = ({ sport, onChange, classnames }) => (
  <div className={classNames({
    [styles.setSportLevel]: true,
    [classnames]: true,
  })}
  >
    <h2>{sport?.sport?.displayName}</h2>
    <Slider
      marks={PROFILE_SPORTS_LEVEL_SLIDER}
      tooltipVisible={false}
      step={null}
      defaultValue={SPORTS_LEVELS[sport?.level]}
      onChange={onChange}
    />
  </div>
);

SetLevel.defaultProps = {
  sport: null,
  onChange: null,
  classnames: null,
};

SetLevel.propTypes = {
  sport: PropTypes.shape({
    sport: PropTypes.shape({
      displayName: PropTypes.string,
    }),
    level: PropTypes.string,
  }),
  onChange: PropTypes.func,
  classnames: PropTypes.string,
};

export default SetLevel;
