import React, { useContext } from 'react';
import { Menu } from 'antd';
import {
  UserOutlined,
  HomeOutlined, CalendarOutlined,
} from '@ant-design/icons';
import {
  ROUTE_MY_PROFILE,
  ROUTE_SELECT_UNIVERSITY,
  ROUTE_UNIVERSITY,
  ROUTE_BOOKING,
} from 'constants/routes';
import { ProfileContext } from 'contextProviders/ProfileProvider';
import PropTypes from 'prop-types';

const SiderMenu = ({
  match: {
   params: {
     universityName,
   } = {},
   path,
  },
  history,
}) => {
  const {
    fetchedMyProfile: {
      getMyProfile: {
        university,
      } = {},
    } = {},
  } = useContext(ProfileContext);

  const { centerName } = university || {};

  return (
    <div style={{ width: 185 }}>
      <Menu
        defaultSelectedKeys={[path]}
        mode="inline"
      >
        <Menu.Item
          key={ROUTE_MY_PROFILE}
          icon={<UserOutlined />}
          onClick={() => history.push(ROUTE_MY_PROFILE)}
        >
          My Profile
        </Menu.Item>
        <Menu.Item
          key={universityName ? ROUTE_UNIVERSITY : ROUTE_SELECT_UNIVERSITY}
          icon={<HomeOutlined />}
          onClick={() => history.push(ROUTE_SELECT_UNIVERSITY)}
        >
          {centerName}
        </Menu.Item>
        <Menu.Item
          key={ROUTE_BOOKING}
          icon={<CalendarOutlined />}
          onClick={() => history.push(ROUTE_BOOKING)}
        >
          My Booking
        </Menu.Item>
      </Menu>
    </div>
  );
};

SiderMenu.defaultProps = {
  match: null,
  history: null,
};

SiderMenu.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      universityName: PropTypes.string,
    }),
    path: PropTypes.string,
  }),
};

export default SiderMenu;
