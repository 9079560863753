import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Form,
  Input,
  Row,
  Col,
} from 'antd';
import { UnlockOutlined } from '@ant-design/icons';
import { Auth } from 'aws-amplify';
import { confirmNewPasswordValidator, passwordValidator } from 'helpers/validators';
import { ROUTE_LOGIN } from 'constants/routes';
import ErrorText from 'components/common/ErrorText';
import styles from './index.module.scss';

const ResetPassword = ({ history, match }) => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isPasswordReset, setPasswordReset] = useState(false);
  const { email } = match?.params;

  const handleResetPassword = useCallback(async ({
   newPassword,
   code0,
   code1,
   code2,
   code3,
   code4,
   code5,
  }) => {
    try {
      setLoading(true);
      const code = `${code0}${code1}${code2}${code3}${code4}${code5}`;
      await Auth.forgotPasswordSubmit(email, code, newPassword);
      setLoading(false);
      setPasswordReset(true);
    } catch (e) {
      setLoading(false);
      setError(e.message);
    }
  }, [email]);

  const handleClickClose = useCallback(() => history.push(ROUTE_LOGIN),
    [history]);

  return (
    <>
      {!isPasswordReset && (
        <Row className={styles?.wrapper}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 20 }} lg={{ span: 18 }} xl={{ span: 20 }}>
            <h1 className={styles?.loginH1}>Reset password</h1>
            <Form
              name="basic"
              initialValues={{
                remember: true,
              }}
              onFinish={handleResetPassword}
            >
              <div className={styles?.verificationCode}>We have sent you a verification code</div>
              <Row gutter={[20, 8]} justify="space-around" style={{ margin: 0 }}>
                <Col span={4} align="center">
                  <Form.Item
                    className={styles?.code}
                    name="code0"
                    type="number"
                    rules={[{ required: true, message: ' ', max: 1 }]}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                </Col>
                <Col span={4} align="center">
                  <Form.Item
                    className={styles?.code}
                    name="code1"
                    rules={[{ required: true, message: ' ', max: 1 }]}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                </Col>
                <Col span={4} align="center">
                  <Form.Item
                    className={styles?.code}
                    name="code2"
                    rules={[{ required: true, message: ' ', max: 1 }]}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                </Col>
                <Col span={4} align="center">
                  <Form.Item
                    className={styles.code}
                    name="code3"
                    rules={[{ required: true, message: ' ', max: 1 }]}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                </Col>
                <Col span={4} align="center">
                  <Form.Item
                    className={styles.code}
                    name="code4"
                    rules={[{ required: true, message: ' ', max: 1 }]}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                </Col>
                <Col span={4} align="center">
                  <Form.Item
                    className={styles.code}
                    name="code5"
                    rules={[{ required: true, message: ' ', max: 1 }]}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                  () => passwordValidator({ passwordLength: 8 }),
                ]}
              >
                <Input.Password
                  allowClear
                  placeholder="New password"
                  prefix={<UnlockOutlined />}
                />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                dependencies={['newPassword']}
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  confirmNewPasswordValidator,
                ]}
              >
                <Input.Password
                  allowClear
                  placeholder="Confirm new password"
                  prefix={<UnlockOutlined />}
                />
              </Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={isLoading}
                loading={isLoading}
              >
                Reset
              </Button>
            </Form>
            <ErrorText text={error} />
          </Col>
        </Row>
      )}

      {isPasswordReset && (
        <Row className={styles.wrapper}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 20 }} lg={{ span: 18 }} xl={{ span: 20 }}>
            <h1 className={styles.loginH1}>Successful!</h1>
            <img src="/mobile.svg" alt="" className={styles.mobile} />

            <div className={styles.successfullyChangedPassword}>You have successfully changed password.</div>
            <div className={styles.useNewPassword}>Please use your new password when logging in.</div>

            <Button
              type="primary"
              htmlType="submit"
              onClick={handleClickClose}
            >
              Log In
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};

ResetPassword.defaultProps = {
  history: {},
  match: {},
};

ResetPassword.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      email: PropTypes.string,
    }),
  }),
};

export default ResetPassword;
