import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { GET_CAMPUS_SPORT_CLASSES_DATES } from 'api/queries';

const HelperSportClassesDates = ({
  fetchedMyClassDates: {
    loading,
    getNextClassDates,
  } = {},
  setIsLoading,
  setAvailableDates,
}) => {
  useEffect(() => setIsLoading(loading),
    [setIsLoading, loading]);

  useEffect(() => getNextClassDates && setAvailableDates(getNextClassDates),
     [getNextClassDates, setAvailableDates]);

  return null;
};

HelperSportClassesDates.defaultProps = {
  university: null,
  campus: null,
  category: null,
  sport: null,
  fetchedMyClassDates: null,
  setIsLoading: null,
};

HelperSportClassesDates.propTypes = {
  university: PropTypes.string,
  campus: PropTypes.string,
  category: PropTypes.string,
  sport: PropTypes.string,
  fetchedMyClassDates: PropTypes.shape({
    loading: PropTypes.bool,
    getNextClassDates: PropTypes.arrayOf(PropTypes.string),
  }),
  setIsLoading: PropTypes.func,
};

const withData = graphql(GET_CAMPUS_SPORT_CLASSES_DATES, {
  name: 'fetchedMyClassDates',
  options: ({
    university,
    campus,
    category,
    sport,
  }) => ({
    variables: {
      university,
      campus,
      category,
      sport,
    },
  }),
});

export default withData(HelperSportClassesDates);
