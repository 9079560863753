export const ROUTE_LOGIN = '/login';
export const ROUTE_FORGOT_PASSWORD = '/forgot-password';
export const ROUTE_RESET_PASSWORD = '/reset-password';
export const ROUTE_CREATE_ACCOUNT = '/create-account';

export const ROUTE_MY_PROFILE = '/';
export const ROUTE_SELECT_UNIVERSITY = '/select-university';
export const ROUTE_UNIVERSITY = '/university/:universityName';
export const ROUTE_BOOKING = '/booking';

export const ROUTE_TERMS_AND_CONDITIONS = '/terms-and-conditions';
export const ROUTE_PRIVACY_POLICY = '/privacy-policy';
export const ROUTE_CONTACT_US = '/contact-us';
