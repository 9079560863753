import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import {
 Avatar, Menu, Dropdown,
} from 'antd';
import { UserOutlined, DownOutlined, LogoutOutlined } from '@ant-design/icons';
import { ProfileContext } from 'contextProviders/ProfileProvider';
import {
  ROUTE_MY_PROFILE,
  ROUTE_LOGIN,
  ROUTE_TERMS_AND_CONDITIONS,
  ROUTE_PRIVACY_POLICY, ROUTE_CONTACT_US,
} from 'constants/routes';

const HeaderMenu = ({ history, checkIfIsLoggedIn }) => {
  const {
    fetchedMyProfile: {
      getMyProfile: {
        firstName = ' ',
        lastName = ' ',
        profilePic,
      } = {},
    } = {},
  } = useContext(ProfileContext);

  const handleLogout = useCallback(async () => {
    await Auth.signOut();
    await checkIfIsLoggedIn();
    document.location.assign(ROUTE_LOGIN);
  }, [checkIfIsLoggedIn]);

  const menu = (
    <Menu>
      <Menu.Item>
        <a
          rel=""
          href="/"
          onClick={e => {
          e.preventDefault();
          history.push(ROUTE_MY_PROFILE);
        }}
        >
          Profile
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          rel=""
          href="/"
          onClick={e => {
          e.preventDefault();
          history.push(ROUTE_PRIVACY_POLICY);
        }}
        >
          Privacy and security
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          rel=""
          href="/"
          onClick={e => {
          e.preventDefault();
          history.push(ROUTE_TERMS_AND_CONDITIONS);
        }}
        >
          Terms and conditions
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          rel=""
          href="/"
          onClick={e => {
            e.preventDefault();
            history.push(ROUTE_CONTACT_US);
          }}
        >
          Contact us
        </a>
      </Menu.Item>
      <Menu.Item>
        <button
          onClick={handleLogout}
        ><LogoutOutlined /> Log Out</button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <span className="ant-dropdown-link" onClick={e => e.preventDefault()}>
        <Avatar size={40} icon={<UserOutlined />} src={profilePic} />
        <span style={{ padding: '0 10px' }}>{`${firstName} ${lastName}`}</span>
        <DownOutlined />
      </span>
    </Dropdown>
  );
};

HeaderMenu.defaultProps = {
  history: null,
  checkIfIsLoggedIn: null,
};

HeaderMenu.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  checkIfIsLoggedIn: PropTypes.func,
};

export default HeaderMenu;
