import React, {
  useState, useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Slider,
} from 'antd';
import {
  DeleteOutlined,
} from '@ant-design/icons';
import findKey from 'lodash/findKey';
import classNames from 'classnames';
import {
  DASHBOARD_SPORTS_LEVEL_SLIDER,
  DASHBOARD_SPORTS_LEVELS,
  SPORT_LEVEL_EMPTY,
} from 'constants/sports';
import styles from './index.module.scss';

const Sport = ({ sport, onChange, onDelete }) => {
  const [disable, setDisable] = useState(false);
  const [level, setLevel] = useState(sport.level);

  useEffect(() => setLevel(sport.level), [sport]);

  const handleChange = useCallback(async value => {
    let lvl = findKey(DASHBOARD_SPORTS_LEVELS, i => i === value);
    if (lvl === SPORT_LEVEL_EMPTY) lvl = 'beginner';
    setLevel(lvl);
    setDisable(true);
    await onChange(lvl);
    setDisable(false);
  }, [onChange]);

  return (
    <Row
      className={classNames({
        [styles.sport]: true,
        'dashboard-level-selector': true,
      })}
      type="flex"
    >
      <Col>
        <div
          className={styles.sportImage}
          style={{
          '--color': '#949494',
          '--image': `url(${sport.imageUrl})`,
          '--image2': `url(${sport.imageUrl})`,
          '--blend-top': 'multiply',
          '--blend-bottom': 'multiply',
          '--color-v': 'transparent',
        }}
        />
      </Col>
      <Col span={12}>
        <div className={styles.sportProfile}>
          <h4>{sport.displayName}</h4>
          <div>
            <Slider
              disable={disable}
              step={null}
              marks={DASHBOARD_SPORTS_LEVEL_SLIDER}
              tooltipVisible={false}
              value={DASHBOARD_SPORTS_LEVELS[level]}
              onChange={handleChange}
            />
          </div>
          <div>{level}</div>
        </div>
      </Col>
      <Col className={styles.sportDeleteBox}>
        <div className={styles.sportDelete}>
          <DeleteOutlined
            className={styles.sportDeleteIcon}
            onClick={onDelete}
          />
        </div>
      </Col>
    </Row>
  );
};

Sport.defaultProps = {
  sport: null,
  onChange: null,
  onDelete: null,
};

Sport.propTypes = {
  sport: PropTypes.shape({
    displayName: PropTypes.string,
    imageUrl: PropTypes.string,
    level: PropTypes.string,
  }),
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};

export default Sport;
