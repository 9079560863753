import React, { useState, useEffect } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import { ApolloProvider } from 'react-apollo';
import AWSAppSyncClient, { defaultDataIdFromObject } from 'aws-appsync';
import { Rehydrated } from 'aws-appsync-react';

import ENV from 'api/env';

import ModalProvider from 'contextProviders/ModalProvider';

import Routes from './routes';

const { AWS, GOOGLE_MAPS_API_KEY } = ENV;

const AmplifyConfig = {
  Auth: AWS.auth,
};

Amplify.configure(AmplifyConfig);

const client = new AWSAppSyncClient({
  url: AWS.appSync.aws_appsync_graphqlEndpoint,
  region: AWS.appSync.aws_appsync_region,
  auth: {
    type: AWS.appSync.aws_appsync_authenticationType,
    jwtToken: async () => (await Auth.currentSession()).idToken.jwtToken,
  },
  cacheOptions: {
    dataIdFromObject: (obj) => {
      const id = defaultDataIdFromObject(obj);

      return id;
    },
  },
  // next option is enablen according to fixing following - https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/282
  disableOffline: true,
});

const App = () => {
  const [isGoogleMapLoaded, setIsGoogleMapLoaded] = useState(false);

  const finishMapInit = () => {
    setIsGoogleMapLoaded(true);
  };

  useEffect(() => {
    window.initMap = finishMapInit;
    const gmapScriptEl = document.createElement('script');
    gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places&callback=initMap&language=en`;
    document
      .querySelector('body')
      .insertAdjacentElement('beforeend', gmapScriptEl);
  }, []);

  if (!isGoogleMapLoaded) {
    return null;
  }

  return (
    <ApolloProvider client={client}>
      <Rehydrated>
        <ModalProvider>
          <Routes />
        </ModalProvider>
      </Rehydrated>
    </ApolloProvider>
  );
};

export default App;
