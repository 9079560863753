import gql from 'graphql-tag';

import { userProfileFragment, locationFragment } from 'api/fragments';

export const GET_SPORTS_LIST = gql`
  query getSports($nextToken: String) {
    listSports(nextToken: $nextToken) {
      sports {
        imageUrl
        name
        count
        displayName
      }
      nextToken
    }
  }
`;

export const GET_UNIVERSITIES_LIST = gql`
  query getUniversities {
    listUniversities {
      universities {
        name
        imageUrl
        profilePic
        coverPhoto
        centerName
        profileName
        profileTypes
        campuses {
          campuses {
            name
            displayName
            categories {
              name
              sports {
                name
                description
                coach {
                  id
                  firstName
                  lastName
                  profilePic
                  sports {
                    sport {
                      name
                      displayName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_UNIVERSITIES_LIST_FOR_CREATE_ACCOUNT = gql`
  query getUniversities {
    listUniversities {
      universities {
        name
        centerName
        profileName
        location {
          ...locationFragment
        }
        profileTypes
      }
    }
  }
  ${locationFragment}
`;

export const GET_MY_PROFILE = gql`
  query GetMyProfile {
    getMyProfile {
      ...userProfileFragment
    }
  }
  ${userProfileFragment}
`;

export const GET_USER_PROFILE = gql`
  query GetUserProfile($id: ID!) {
    getProfile(id: $id) {
      id
      firstName
      lastName
      activitiesCount
      profileType
      university {
        name
        profileName
        centerName
      }
      gender
      sports {
        sport {
          name
          displayName
        }
        level
      }
      activities {
        activities {
          activityId
          happeningAt
          sport {
            displayName
          }
          location {
            ...locationFragment
          }
          motivation
        }
      }
      location {
        ...locationFragment
      }
      distance
      qrCode
      profilePic
      coverPhoto
    }
  }
  ${locationFragment}
`;

export const GET_USER_SPORTS = gql`
  query GetUserSports($id: ID!) {
    getProfile(id: $id) {
      id
      firstName
      lastName
      sports {
        sport {
          name
          displayName
        }
        level
      }
    }
  }
`;

export const GET_UPLOAD_URL = gql`
  query GetUploadUrl($contentType: String) {
    getUploadUrl(contentType: $contentType)
  }
`;

export const GET_MY_PRIVATE_CHATS = gql`
  query GetMyPrivateChats($limit: Int!, $nextToken: String) {
    getMyPrivateChats(limit: $limit, nextToken: $nextToken) {
      chats {
        id
        otherUser {
          id
          firstName
          lastName
          profilePic
          sports {
            sport {
              name
              displayName
            }
            level
          }
        }
        lastModified
        hasUnreadMessages
        lastMessage {
          message
        }
        isMuted
      }
      nextToken
    }
  }
`;

export const GET_MY_CHAMPIONSHIP_CHATS = gql`
  query ListMyChampionshipTeamChats($limit: Int!, $nextToken: String) {
    listMyChampionshipTeamChats(limit: $limit, nextToken: $nextToken) {
      chats {
        name
        teamId
        lastModified
        hasUnreadMessages
        isMuted
        lastMessage {
          message
        }
      }
      nextToken
    }
  }
`;

export const GET_MY_CHAMPIONSHIP_MATCH_CHATS = gql`
  query ListMyChampionshipMatchChats($limit: Int!, $nextToken: String) {
    listMyChampionshipMatchChats(limit: $limit, nextToken: $nextToken) {
      chats {
        name
        teamId
        matchId
        lastModified
        hasUnreadMessages
        isMuted
        lastMessage {
          message
        }
      }
      nextToken
    }
  }
`;

export const GET_MY_ACTIVITIES = gql`
  query GetMyActivities($limit: Int!, $nextToken: String) {
    getMyActivities(limit: $limit, nextToken: $nextToken) {
      activities {
        activityId
        happeningAt
        sport {
          displayName
        }
        motivation
        location {
          name
        }
        members {
          id
          firstName
          lastName
          profilePic
          sports {
            sport {
              name
              displayName
            }
            level
          }
        }
      }
      nextToken
    }
  }
`;

export const GET_USER_ACTIVITIES = gql`
  query GetUserActivities($id: ID!, $limit: Int!, $nextToken: String) {
    getActivities(id: $id, limit: $limit, nextToken: $nextToken) {
      activities {
        activityId
        happeningAt
        sport {
          displayName
        }
        motivation
        location {
          name
        }
        members {
          id
          firstName
          lastName
          profilePic
          sports {
            sport {
              name
              displayName
            }
            level
          }
        }
        distance
      }
      nextToken
    }
  }
`;

export const GET_MY_ACTIVITY_MEMBERSHIP = gql`
  query GetMyActivityMembership($limit: Int!, $nextToken: String) {
    getMyActivityMemberships(limit: $limit, nextToken: $nextToken) {
      memberships {
        ... on Activity {
          activityId
          happeningAt
          isMuted
          lastModified
          lastMessage {
            message
          }
          hasUnreadMessages
          sport {
            displayName
          }
          motivation
          location {
            name
          }
          user {
            id
            firstName
            lastName
            profilePic
            sports {
              sport {
                name
                displayName
              }
              level
            }
          }
          members {
            id
            firstName
            lastName
            profilePic
            sports {
              sport {
                name
                displayName
              }
              level
            }
          }
        }
        ... on MyActivity {
          activityId
          happeningAt
          isMuted
          lastModified
          lastMessage {
            message
          }
          hasUnreadMessages
          sport {
            displayName
          }
          motivation
          location {
            name
          }
          user {
            id
            firstName
            lastName
            profilePic
            sports {
              sport {
                name
                displayName
              }
              level
            }
          }
          members {
            id
            firstName
            lastName
            profilePic
            sports {
              sport {
                name
                displayName
              }
              level
            }
          }
          joinRequestCount
        }
      }
      nextToken
    }
  }
`;

export const GET_JOIN_ACTIVITIES_LIST = gql`
  query GetJoinActivitiesList($limit: Int, $nextToken: String) {
    getAllJoinActivityRequests(limit: $limit, nextToken: $nextToken) {
      requests {
        requestId
        message
        timestamp
        from {
          id
          firstName
          lastName
          profilePic
          sports {
            sport {
              name
              displayName
            }
            level
          }
        }
        sport {
          displayName
        }
        activity {
          activityId
          happeningAt
          joinRequestCount
          sport {
            displayName
          }
          location {
            name
          }
          motivation
          members {
            id
            firstName
            lastName
            profilePic
            sports {
              sport {
                name
                displayName
              }
              level
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const GET_HEY_LETS_PLAY_REQUESTS = gql`
  query GetHeyLetsPlayRequests($limit: Int!, $nextToken: String) {
    getLetsPlayRequests(limit: $limit, nextToken: $nextToken) {
      requests {
        requestId
        message
        timestamp
        from {
          id
          firstName
          lastName
          profilePic
          sports {
            sport {
              name
              displayName
            }
            level
          }
        }
        sport {
          displayName
        }
      }
      nextToken
    }
  }
`;

export const GET_ACTIVITY_JOIN_ACTIVITIES_LIST = gql`
  query GetActivityJoinActivitiesList(
    $activityId: ID!
    $limit: Int
    $nextToken: String
  ) {
    getJoinActivityRequests(
      activityId: $activityId
      limit: $limit
      nextToken: $nextToken
    ) {
      requests {
        requestId
        message
        timestamp
        from {
          id
          firstName
          lastName
          profilePic
          sports {
            sport {
              name
              displayName
            }
            level
          }
        }
        activity {
          activityId
          happeningAt
          joinRequestCount
          sport {
            displayName
          }
          location {
            name
          }
          motivation
          members {
            id
            firstName
            lastName
            profilePic
            sports {
              sport {
                name
                displayName
              }
              level
            }
          }
        }
        sport {
          displayName
        }
      }
      nextToken
    }
  }
`;

export const GET_MY_PRIVATE_CHAT_MESSAGES = gql`
  query GetMyPrivateChatMessages(
    $otherUserId: ID!
    $limit: Int!
    $nextToken: String
  ) {
    getMyPrivateChatMessages(
      otherUserId: $otherUserId
      limit: $limit
      nextToken: $nextToken
    ) {
      messages {
        id
        from {
          id
          firstName
          lastName
          profilePic
        }
        timestamp
        message
        info {
          sport {
            displayName
          }
          user {
            id
            firstName
            lastName
            profilePic
          }
        }
      }
      nextToken
    }
  }
`;

export const GET_ACTIVITY_CHAT_MESSAGES = gql`
  query GetActivityChatMessages(
    $activityId: ID!
    $limit: Int!
    $nextToken: String
  ) {
    getActivityMessages(
      activityId: $activityId
      limit: $limit
      nextToken: $nextToken
    ) {
      messages {
        id
        from {
          id
          firstName
          lastName
          profilePic
        }
        timestamp
        message
      }
      nextToken
    }
  }
`;

export const HAS_UNREAD_MESSAGES = gql`
  query HasUnread_Messages {
    hasUnreadMessages
  }
`;

export const GET_SCANNED_USERS = gql`
  query GetScannedUsers($nextToken: String) {
    getMyScannedStudents(nextToken: $nextToken) {
      users {
        campus
        category
        sport
        date
        classId
        user {
          id
          firstName
          lastName
          profilePic
          profileType
          sports {
            sport {
              name
              displayName
            }
            level
          }
        }
      }
      nextToken
    }
  }
`;

export const SEARCH = gql`
  query search(
    $limit: Int!
    $latitude: Float!
    $longitude: Float!
    $sport: String
    $universityName: String
    $activitiesOnly: Boolean
    $nextToken: String
  ) {
    search(
      limit: $limit
      latitude: $latitude
      longitude: $longitude
      sport: $sport
      universityName: $universityName
      activitiesOnly: $activitiesOnly
      nextToken: $nextToken
    ) {
      entities {
        ... on PublicProfile {
          id
          firstName
          lastName
          profilePic
          sports {
            sport {
              name
              displayName
            }
            level
          }
          location {
            name
          }
          distance
        }
        ... on University {
          name
          centerName
          profileName
          profilePic
          coverPhoto
          profileTypes
          campuses {
            campuses {
              name
              displayName
              categories {
                name
                sports {
                  name
                  description
                  coach {
                    id
                    firstName
                    lastName
                    profilePic
                    sports {
                      sport {
                        name
                        displayName
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ... on Activity {
          activityId
          sport {
            displayName
          }
          happeningAt
          motivation
          hasAskedToJoin
          user {
            id
            firstName
            lastName
            profilePic
            sports {
              sport {
                name
                displayName
              }
              level
            }
          }
          location {
            name
          }
          distance
        }
      }
      nextToken
    }
  }
`;

export const GET_MY_UNIVERSITY_PROFILE_TYPE = gql`
  query GetMyUniversityProfileType($universityName: String!) {
    getMyUniversityProfileType(universityName: $universityName)
  }
`;

export const GET_CAMPUS_SPORT_CLASSES = gql`
  query GetCampusSportClasses(
    $university: String!
    $campus: String!
    $category: String!
    $sport: String!
    $date: AWSDate!
  ) {
    getCampusSportClasses(
      university: $university
      campus: $campus
      category: $category
      sport: $sport
      date: $date
    ) {
      classId
      date
      startTime
      endTime
      name
      maxParticipants
      participantsCount
      isBooked
      sportName
      status
    }
  }
`;

export const GET_CAMPUS_SPORT_PARTICIPANTS = gql`
  query ListCampusSportParticipants(
    $classId: ID!
    $limit: Int!
    $nextToken: String
  ) {
    listCampusSportParticipants(
      classId: $classId
      limit: $limit
      nextToken: $nextToken
    ) {
      participants {
        id
        firstName
        lastName
        profilePic
        bookAnonymously
        sports {
          sport {
            name
            displayName
          }
          level
        }
      }
      nextToken
    }
  }
`;

export const GET_MY_CAMPUS_SPORT_CLASSES = gql`
  query ListMyCampusSportClasses($limit: Int!, $nextToken: String) {
    listMyCampusSportClasses(limit: $limit, nextToken: $nextToken) {
      classes {
        classId
        date
        startTime
        endTime
        name
        maxParticipants
        participantsCount
        isBooked
        sportName
      }
      nextToken
    }
  }
`;

export const GET_CAMPUS_SPORT_CLASSES_DATES = gql`
  query GetNextClassDates(
    $university: String!
    $campus: String!
    $category: String!
    $sport: String!
  ) {
    getNextClassDates(
      university: $university
      campus: $campus
      category: $category
      sport: $sport
    )
  }
`;

export const GET_ACTIVITY = gql`
  query GetActivity($activityId: ID!) {
    getActivity(activityId: $activityId) {
      activityId
      happeningAt
      motivation
      sport {
        displayName
      }
      location {
        name
      }
      user {
        id
        firstName
        lastName
        profilePic
        sports {
          sport {
            name
            displayName
          }
          level
        }
      }
    }
  }
`;

export const GET_JOIN_ACTIVITY_REQUEST = gql`
  query GetJoinActivityRequest($requestId: ID!) {
    getJoinActivityRequest(requestId: $requestId) {
      requestId
      message
      timestamp
      from {
        id
        firstName
        lastName
        profilePic
        sports {
          sport {
            name
            displayName
          }
          level
        }
      }
      sport {
        displayName
      }
      activity {
        activityId
        happeningAt
        isMuted
        lastMessage {
          message
        }
        hasUnreadMessages
        sport {
          displayName
        }
        motivation
        location {
          name
        }
        user {
          id
          firstName
          lastName
          profilePic
          sports {
            sport {
              name
              displayName
            }
            level
          }
        }
        members {
          id
          firstName
          lastName
          profilePic
          sports {
            sport {
              name
              displayName
            }
            level
          }
        }
        joinRequestCount
      }
    }
  }
`;

export const GET_MY_CHAMPIONSHIP_TEAM_MEMBERS = gql`
  query ListMyChampionshipTeamMembers(
    $teamId: ID!
    $limit: Int!
    $nextToken: String
  ) {
    listMyChampionshipTeamMembers(
      teamId: $teamId
      limit: $limit
      nextToken: $nextToken
    ) {
      members {
        id
        firstName
        lastName
        activitiesCount
        profileType
        university {
          name
          profileName
          centerName
        }
        profilePic
        gender
        sports {
          sport {
            name
            displayName
          }
          level
        }
      }
      nextToken
    }
  }
`;

export const GET_MY_CHAMPIONSHIP_MATCH_TEAM_MEMBERS = gql`
  query ListMyChampionshipMatchMembers(
    $matchId: ID!
    $limit: Int!
    $nextToken: String
  ) {
    listMyChampionshipMatchMembers(
      matchId: $matchId
      limit: $limit
      nextToken: $nextToken
    ) {
      members {
        id
        firstName
        lastName
        activitiesCount
        profileType
        university {
          name
          profileName
          centerName
        }
        profilePic
        gender
        sports {
          sport {
            name
            displayName
          }
          level
        }
      }
      nextToken
    }
  }
`;

export const GET_MY_CHAMPIONSHIP_TEAM_CHAT_MESSAGES = gql`
  query GetMyChampionshipTeamChatMessages(
    $teamId: ID!
    $limit: Int!
    $nextToken: String
  ) {
    getMyChampionshipTeamChatMessages(
      teamId: $teamId
      limit: $limit
      nextToken: $nextToken
    ) {
      messages {
        id
        from {
          id
          firstName
          lastName
          profilePic
        }
        timestamp
        message
      }
      nextToken
    }
  }
`;

export const GET_MY_CHAMPIONSHIP_TEAM_MATCH_CHAT_MESSAGES = gql`
  query GetMyChampionshipMatchChatMessages(
    $matchId: ID!
    $limit: Int!
    $nextToken: String
  ) {
    getMyChampionshipMatchChatMessages(
      matchId: $matchId
      limit: $limit
      nextToken: $nextToken
    ) {
      messages {
        id
        from {
          id
          firstName
          lastName
          profilePic
        }
        timestamp
        message
      }
      nextToken
    }
  }
`;

export const GET_CHAMPIONSHIP_MATCH = gql`
  query GetChampionshipMatch($matchId: ID!) {
    getChampionshipMatch(matchId: $matchId) {
      matchId
      teamId
      name
      location {
        name
      }
      date
      time
      squad {
        id
      }
    }
  }
`;

export const GET_CHAMPIONSHIP_TEAM = gql`
  query GetChampionshipTeam($teamId: ID!) {
    getChampionshipTeam(teamId: $teamId) {
      teamId
      name
      membersCount
    }
  }
`;
