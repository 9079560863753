import React, {
  useCallback, useContext, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { ITEMS_PER_PAGE } from 'constants/general';
import { Spin, Button } from 'antd';
import { BookingContext } from 'contextProviders/BookingProvider';
import SportClass from './SportClass';
import styles from '../University/index.module.scss';

const MySportClasses = () => {
  const {
    unBookCampusSportClass,
    fetchedMyClasses,
    fetchedMyClasses: {
      loading,
      listMyCampusSportClasses: { classes = [], nextToken } = {},
    } = {},
  } = useContext(BookingContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isPageOpened, setIsPageOpened] = useState(true);

  useEffect(() => setIsLoading(loading), [loading]);

  useEffect(() => {
    if (isPageOpened && !loading) {
      setIsPageOpened(false);
      fetchedMyClasses.fetchMore({
        variables: {
          limit: ITEMS_PER_PAGE,
          nextToken: null,
        },
        updateQuery: (prev, { fetchMoreResult }) => fetchMoreResult,
      });
    }
  }, [isPageOpened, fetchedMyClasses, loading]);

  const fetchMore = useCallback(async () => {
    if (nextToken) {
      setIsLoading(true);
      await fetchedMyClasses.fetchMore({
        variables: {
          limit: ITEMS_PER_PAGE,
          nextToken,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          const newData = fetchMoreResult;
          newData.listMyCampusSportClasses.classes = [
            ...prev.listMyCampusSportClasses.classes,
            ...fetchMoreResult.listMyCampusSportClasses.classes,
          ];
          return newData;
        },
      });
      setIsLoading(false);
    }
  }, [nextToken, fetchedMyClasses]);

  const unBookClass = useCallback(
    async (id) => {
      const variables = { classId: id };
      const limit = classes.length > 1 ? classes.length - 1 : ITEMS_PER_PAGE;
      setIsLoading(true);
      await unBookCampusSportClass({ variables });
      await fetchedMyClasses.fetchMore({
        variables: {
          limit,
          nextToken: null,
        },
        updateQuery: (prev, { fetchMoreResult }) => fetchMoreResult,
      });
      setIsLoading(false);
    },
    [unBookCampusSportClass, fetchedMyClasses, classes],
  );

  const title = (
    <h4 className={styles.title}>
      Here is your list of bookings. Each booking has a unique QR Code.
    </h4>
  );

  if (classes.length === 0) {
    return (
      <>
        {title}
        <h4 className={styles.title}>No Bookings yet</h4>
      </>
    );
  }

  return (
    <>
      {title}
      <div className="my-sport-classes">
        {classes?.map((sportClass) => (
          <SportClass
            key={sportClass?.classId}
            sportClass={sportClass}
            unBookClass={unBookClass}
          />
        ))}

        <div className={styles.more}>
          {!isLoading && nextToken && <Button onClick={fetchMore}>more</Button>}
          {isLoading && <Spin />}
        </div>
      </div>
    </>
  );
};

MySportClasses.defaultProps = {
  fetchedMyClasses: null,
};

MySportClasses.propTypes = {
  fetchedMyClasses: PropTypes.shape({
    fetchMore: PropTypes.func,
    nextToken: PropTypes.oneOfType([null, PropTypes.string]),
    listMyCampusSportClasses: PropTypes.shape({
      classes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default MySportClasses;
