import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import {
  Button,
  Form,
  Input,
  Checkbox,
  Row,
  Col,
} from 'antd';
import { UnlockOutlined, MailOutlined } from '@ant-design/icons';
import { ROUTE_FORGOT_PASSWORD, ROUTE_CREATE_ACCOUNT, ROUTE_MY_PROFILE } from 'constants/routes';
import ErrorText from 'components/common/ErrorText';
import styles from './index.module.scss';

const Login = ({ history }) => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleLogin = useCallback(async ({ email, password }) => {
    setLoading(true);
    const username = email.toLowerCase();
    try {
      const user = await Auth.signIn(username, password);
      setLoading(false);

      if (user) {
        history.push(ROUTE_MY_PROFILE);
        document.location.reload();
        // here we should get new profile and open dashboard page
      }
    } catch (e) {
      setLoading(false);
      setError(e.message);
    }
  }, [history]);

  const onClickForgotPassword = useCallback(e => {
    e.preventDefault();
    history.push(ROUTE_FORGOT_PASSWORD);
  }, [history]);

  const onClickCreateAccount = useCallback(() => history.push(ROUTE_CREATE_ACCOUNT),
    [history]);

  return (
    <Row className={styles.wrapper}>
      <Col span={24}>
        <h1 className={styles.loginH1}>Log In</h1>
        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={handleLogin}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Please input your email!',
              },
            ]}
          >
            <Input
              allowClear
              placeholder="Email"
              prefix={<MailOutlined />}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password',
              },
            ]}
          >
            <Input.Password
              allowClear
              placeholder="Password"
              prefix={<UnlockOutlined />}
            />
          </Form.Item>

          <Form.Item
            name="remember"
            valuePropName="checked"
            className={styles.checkboxItem}
          >
            <Row>
              <Col span={12}>
                <Checkbox valuePropName="checked">Remember me</Checkbox>
              </Col>
              <Col span={12}>
                <a
href="/"
                  className={styles.forgotPassword}
                  onClick={onClickForgotPassword}
                >Forgot password?</a>
              </Col>
              <Col span={24}>
                <ErrorText text={error} />
              </Col>
            </Row>
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            disabled={isLoading}
            loading={isLoading}
          >
            Log in
          </Button>
        </Form>

        <div className={styles.dontHaveAccount}>Don't have an account?</div>

        <Button
          htmlType="submit"
          onClick={onClickCreateAccount}
        >
          Create Account
        </Button>
      </Col>
    </Row>
  );
};

Login.defaultProps = {
  history: {},
};

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default Login;
