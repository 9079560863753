import React, {
  useState, useCallback, useEffect, useContext,
} from 'react';
import { graphql } from 'react-apollo';
import PropTypes from 'prop-types';
import {
  Button,
  Form,
  Row,
  Col,
  DatePicker,
  Input,
  Radio,
  Select,
} from 'antd';
import moment from 'moment';
import pick from 'lodash/pick';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { UserOutlined, HomeOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { calculateDistance } from 'helpers/distance';
import { GET_UNIVERSITIES_LIST_FOR_CREATE_ACCOUNT } from 'api/queries';
import { EditProfileContext } from 'contextProviders/EditProfileProvider';
import { ProfileContext } from 'contextProviders/ProfileProvider';
import { GENDERS } from 'constants/general';
import { STEPS_INDEXES } from 'constants/registrationSteps';
import AvatarUploader from 'components/common/AvatarUploader';
import LocationSearchInput from 'components/common/LocationSearchInput';
import styles from '../index.module.scss';

const DEFAULT_LOCATION = { latitude: null, longitude: null, name: '' };

const EditProfile = ({
 goToStep, universitiesList: {
  listUniversities: {
    universities = [],
  } = {},
} = {},
}) => {
  const {
    updateProfilePhoto,
  } = useContext(EditProfileContext);

  const {
    fetchedMyProfile,
    fetchedMyProfile: {
      getMyProfile,
    },
    createUserProfile,
    updateUserProfile,
  } = useContext(ProfileContext);

  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  // const [error, setError] = useState(false);
  const [location, setLocation] = useState(DEFAULT_LOCATION);
  const [university, setUniversity] = useState(getMyProfile?.university?.name);
  const [universityOptions, setUniversityOptions] = useState([]);
  const [avatarUrl, setAvatarUrl] = useState(null);

  useEffect(() => {
    if (universities.length > 0) {
      const options = universities
        .map(curUniversity => {
          const distance = location?.latitude
          && location?.longitude
          && curUniversity?.location?.latitude
          && curUniversity?.location?.longitude
            ? calculateDistance(
              location?.latitude,
              location?.longitude,
              curUniversity?.location?.latitude,
              curUniversity?.location?.longitude,
            ) : 0;

          return { ...curUniversity, value: curUniversity?.name, distance };
        })
        .sort((a, b) => {
          return parseFloat(a.distance) - parseFloat(b.distance);
        });

      const uOptions = options.map(u => ({ value: u.name, label: u.centerName }));
      setUniversityOptions(uOptions);
    }
  }, [universities, location]);

  const handleChangeLocation = useCallback(name => {
    if (name) {
      setLocation({ ...location, name });
    } else {
      setLocation(DEFAULT_LOCATION);
    }
  }, [setLocation, location]);

  const handleFilterUniversities = useCallback(
    (inputValue, option) => option?.label?.toLowerCase().indexOf(inputValue?.toLowerCase()) !== -1, [],
  );

  const handleAddressSelect = useCallback(async name => {
    try {
      setLocation({ ...location, name });
      const results = await geocodeByAddress(name);
      const { lat: latitude, lng: longitude } = await getLatLng(results[0]);
      setLocation({ latitude, longitude, name });
    } catch (e) {
      console.error('Error', e);
    }
  }, [location, setLocation]);

  const handleEditProfile = useCallback(async ({
   firstName,
   lastName,
   gender,
   dob,
  }) => {
    try {
      setLoading(true);
      let variables = {};
      if (getMyProfile) {
        variables = {
          ...variables,
          firstName,
          lastName,
          university,
          location,
        };
        await updateUserProfile({ variables });
      } else {
        variables = {
          ...variables,
          firstName,
          lastName,
          university,
          gender,
          location,
          dob: dob.format('YYYY-MM-DD'),
          sports: [],
        };
        await createUserProfile({ variables });
      }
      if (avatarUrl) {
        await updateProfilePhoto({ variables: { url: avatarUrl } });
      }
      setLoading(false);
      if (getMyProfile) {
        await fetchedMyProfile.refetch();
        goToStep(STEPS_INDEXES.SELECTED_SPORTS, true);
      } else {
        window.location.reload();
      }
    } catch (e) {
      setLoading(false);
    }
  }, [location, university, getMyProfile, fetchedMyProfile, avatarUrl, createUserProfile, goToStep, updateProfilePhoto, updateUserProfile]);

  useEffect(() => {
    if (getMyProfile) {
      form.setFieldsValue({
        ...pick(getMyProfile, [
          'firstName',
          'lastName',
          'gender',
        ]),
        dob: moment(getMyProfile?.dob, 'YYYY-MM-DD'),
      });
      setLocation(pick(getMyProfile.location, [
        'name',
        'latitude',
        'longitude',
      ]));
      setUniversity(getMyProfile?.university?.name);
      const { profilePic } = getMyProfile;
      if (profilePic) setAvatarUrl(profilePic);
    }
  }, [getMyProfile, form]);

  const onImageUploaded = useCallback(url => setAvatarUrl(url), [setAvatarUrl]);

  return (
    <Row justify="center">
      <Col>
        <h1 className={styles.loginH1}>Profile</h1>
        <AvatarUploader
          onImageUploaded={onImageUploaded}
          value={avatarUrl}
        />
        <Form
          form={form}
          name="basic"
          initialValues={{
            gender: GENDERS[0],
            location: DEFAULT_LOCATION,
          }}
          onFinish={handleEditProfile}
        >
          <Row>
            <Col span={24} align="center">
              <Form.Item name="gender">
                <Radio.Group
                  name="radiogroup"
                  className={styles.radio}
                >
                  <Row type="flex">
                    {GENDERS.map(g => (
                      <Col span={12} align="center" key={g}>
                        <Radio value={g} disabled={!!getMyProfile}>{g}</Radio>
                      </Col>
                    ))}
                  </Row>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    type: 'string',
                    message: 'Please input your first name!',
                  },
                ]}
              >
                <Input
                  allowClear
                  placeholder="First name"
                  prefix={<UserOutlined />}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="lastName"
                rules={[
                  {
                    required: true,
                    type: 'string',
                    message: 'Please input your last name!',
                  },
                ]}
              >
                <Input
                  allowClear
                  placeholder="Last name"
                  prefix={<UserOutlined />}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="dob"
                rules={[
                  () => ({
                    validator(rule, value) {
                      if (!value || !moment(value)) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise.reject('Please provide your date of birth');
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <DatePicker
                  allowClear
                  placeholder="Date of birth"
                  format="DD-MM-YYYY"
                  disabled={!!getMyProfile}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="location"
                value={location.name}
                rules={[
                  () => ({
                    validator(rule, value) {
                      if (!value) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise.reject('Please provide your location');
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <LocationSearchInput
                  allowClear
                  prefix={<EnvironmentOutlined />}
                  address={location.name}
                  clearAddress={e => {
                    console.log(e);
                  }}
                  onChange={handleChangeLocation}
                  onClick={handleAddressSelect}
                  onSelect={handleAddressSelect}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <span style={{ fontSize: '12px' }}>
                You must choose the university that you are a part of or where you want to exercise and do your sports activities, independently of whether you are a student or not
              </span>
              <Form.Item
                name="university"
                value={university}
                className="withPrefix"
                rules={[
                  () => ({
                    validator() {
                      if (!university) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise.reject('Please provide the name of university or college');
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  value={university || undefined}
                  options={universityOptions}
                  onChange={value => setUniversity(value)}
                  placeholder="University/College"
                  filterOption={handleFilterUniversities}
                />
                <HomeOutlined />
              </Form.Item>
            </Col>

            <Col offset={6} span={12} align="center">
              <Button
                type="primary"
                htmlType="submit"
                disabled={isLoading}
                loading={isLoading}
              >
                {getMyProfile ? 'Next' : 'Save'}
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

EditProfile.defaultProps = {
  goToStep: null,
};

EditProfile.propTypes = {
  goToStep: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  universitiesList: PropTypes.object,
};

const withGetUniversities = graphql(GET_UNIVERSITIES_LIST_FOR_CREATE_ACCOUNT, {
  name: 'universitiesList',
});

export default withGetUniversities(EditProfile);
