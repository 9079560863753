import gql from 'graphql-tag';

export const locationFragment = gql`
  fragment locationFragment on Location {
    name
    latitude
    longitude
  }
`;

export const universityFragment = gql`
  fragment universityFragment on University {
    name
    profileName
    imageUrl
    centerName
  }
`;

export const activityFragment = gql`
  fragment activityFragment on MyActivity {
    activityId
    happeningAt
    sport {
      displayName
    }
    location {
      ...locationFragment
    }
    motivation
    members {
      id
      firstName
      lastName
      profilePic
      sports {
        sport {
          name
          displayName
        }
        level
      }
    }
  }
  ${locationFragment}
`;

export const userProfileFragment = gql`
  fragment userProfileFragment on Profile {
    id
    firstName
    lastName
    activitiesCount
    profileType
    gender
    dob
    university {
      ...universityFragment
    }
    sports {
      sport {
        name
        displayName
      }
      level
    }
    activities {
      activities {
        ...activityFragment
      }
    }
    location {
      ...locationFragment
    }
    qrCode
    profilePic
    coverPhoto
    sportsCard
    bookAnonymously
    coachingClasses {
      classes {
        university {
          name
          centerName
        }
        campus {
          name
          displayName
        }
        category
        sport
      }
    }
    championshipTeams {
      teams {
        teamId
        name
        coordinator {
          id
          firstName
          lastName
          activitiesCount
          profileType
          university {
            name
            profileName
            centerName
          }
          gender
          sports {
            sport {
              name
              displayName
            }
            level
          }
        }
      }
    }
  }
  ${locationFragment}
  ${activityFragment}
  ${universityFragment}
`;
