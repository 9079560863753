import React, {
  useState, useCallback, useContext, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Button,
} from 'antd';
import findKey from 'lodash/findKey';
import { EditProfileContext } from 'contextProviders/EditProfileProvider';
import { SPORTS_LEVELS } from 'constants/sports';
import {
  STEPS_INDEXES,
} from 'constants/registrationSteps';
import { ProfileContext } from 'contextProviders/ProfileProvider';
import { ROUTE_MY_PROFILE } from 'constants/routes';
import SetLevel from './SetLevel';
import styles from '../index.module.scss';

const SelectLevels = ({
  goToStep,
  history,
  toggleModal,
  isModal,
}) => {
  const {
    selectedSports,
    setSelectedSports,
    updateUserSports,
  } = useContext(EditProfileContext);
  const {
    fetchedMyProfile,
    fetchedMyProfile: {
      getMyProfile: { sports: profileSports = [] } = {},
    },
  } = useContext(ProfileContext);

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedSports.length === 0) {
      setSelectedSports(profileSports);
    }
  }, [profileSports, selectedSports, setSelectedSports]);

  const handleChangeLevel = useCallback((level, sport) => {
    const updatedSelectedSports = selectedSports.map(item => item?.sport?.name === sport?.sport?.name
      ? { ...item, level: findKey(SPORTS_LEVELS, i => i === level) }
      : item);
    setSelectedSports(updatedSelectedSports);
  }, [selectedSports, setSelectedSports]);

  const handleClickNext = useCallback(async () => {
    try {
      setLoading(true);
      const sports = selectedSports
        .map(item => ({ sport: item?.sport?.name, level: item?.level }));
      const variables = { sports };
      await updateUserSports({ variables });
      await fetchedMyProfile.refetch();
      setLoading(false);
      if (!isModal && history) history.push(ROUTE_MY_PROFILE);
      if (isModal && toggleModal) toggleModal();
    } catch (e) {
      setLoading(false);
    }
  }, [selectedSports, isModal, history, toggleModal, fetchedMyProfile, updateUserSports]);

  const handleClickBack = () => goToStep(STEPS_INDEXES.SELECTED_SPORTS);

  return (
    <Row justify="center">
      <Col span={24}>
        <h1 className={styles.loginH1}>Select your level</h1>

        <div>
          {selectedSports.map(item => (
            <SetLevel
              key={item?.sport?.name}
              sport={item}
              onChange={value => handleChangeLevel(value, item)}
              classnames="set-level-selector"
            />
          ))}
        </div>

        {isModal && (
        <Row>
          <Col span={12} offset={6}>
            <Button
              onClick={handleClickNext}
              style={{ width: '100%' }}
              type="primary"
            >
              Finish
            </Button>
          </Col>
        </Row>
         )}

        {!isModal && (
          <Row>
            <Col span={12}>
              <Button
              disabled={isLoading}
              onClick={handleClickBack}
              >
                Back
              </Button>
            </Col>
            <Col span={12} align="right">
              <Button
              type="primary"
              disabled={isLoading}
              loading={isLoading}
              onClick={handleClickNext}
              >
                {isModal ? 'Save' : 'Next' }
              </Button>
            </Col>
          </Row>
        )}

      </Col>
    </Row>
  );
};

SelectLevels.defaultProps = {
  goToStep: null,
  history: null,
  toggleModal: null,
  isModal: false,
};

SelectLevels.propTypes = {
  goToStep: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  toggleModal: PropTypes.func,
  isModal: PropTypes.bool,
};

export default SelectLevels;
