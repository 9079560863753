import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

const Close = ({ onClick }) => {
  return (
    <CloseOutlined
      className={styles.close}
      onClick={onClick}
      style={{ cursor: onClick ? 'pointer' : 'default' }}
    />
  );
};

export default Close;
