import gql from 'graphql-tag';

import { userProfileFragment } from 'api/fragments';

export const CREATE_USER_PROFILE = gql`
  mutation CreateUserProfile(
    $firstName: String!
    $lastName: String!
    $university: String
    $gender: Gender!
    $dob: AWSDate!
    $sports: [ProfileSportInput!]!
    $location: LocationInput!
    $profilePic: AWSURL
    $coverPhoto: AWSURL
  ) {
    createProfile(
      firstName: $firstName
      lastName: $lastName
      university: $university
      gender: $gender
      dob: $dob
      sports: $sports
      location: $location
      profilePic: $profilePic
      coverPhoto: $coverPhoto
    ) {
      ...userProfileFragment
    }
  }
  ${userProfileFragment}
`;

export const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserProfile(
    $firstName: String!
    $lastName: String!
    $university: String
    $location: LocationInput!
    $bookAnonymously: Boolean
  ) {
    updateProfile(
      firstName: $firstName
      lastName: $lastName
      university: $university
      location: $location
      bookAnonymously: $bookAnonymously
    )
  }
`;

export const DELETE_USER_PROFILE = gql`
  mutation DeleteUserProfile {
    deleteProfile
  }
`;

export const REMOVE_USER_DEVICE_TOKEN = gql`
  mutation RemoveUserDeviceToken {
    removeDeviceToken
  }
`;

export const BLOCK_USER = gql`
  mutation BlockUser($userId: ID!) {
    blockUser(userId: $userId)
  }
`;

export const MUTE_PRIVATE_CHAT = gql`
  mutation MutePrivateChat($userId: ID!) {
    mutePrivateChat(userId: $userId)
  }
`;

export const UNMUTE_PRIVATE_CHAT = gql`
  mutation UnmutePrivateChat($userId: ID!) {
    unmutePrivateChat(userId: $userId)
  }
`;

export const HIDE_PRIVATE_CHAT = gql`
  mutation HidePrivateChat($userId: ID!) {
    hidePrivateChat(userId: $userId)
  }
`;

export const HEY_LETS_PLAY = gql`
  mutation HeyLetsPlay($userId: ID!, $sport: String!, $message: String!) {
    heyLetsPlay(userId: $userId, sport: $sport, message: $message)
  }
`;

export const HIDE_ACTIVITY_CHAT = gql`
  mutation HideActivityChat($activityId: ID!) {
    hideActivityChat(activityId: $activityId)
  }
`;

export const UPDATE_PROFILE_PHOTO = gql`
  mutation UpdateProfilePicture($url: AWSURL!) {
    updateProfilePic(url: $url)
  }
`;

export const UPDATE_COVER_PHOTO = gql`
  mutation UpdateCoverPicture($url: AWSURL!) {
    updateCoverPhoto(url: $url)
  }
`;

export const UPDATE_USER_SPORTS = gql`
  mutation UpdateUserSports($sports: [ProfileSportInput!]!) {
    updateSports(sports: $sports)
  }
`;

export const UPDATE_USER_PROFILE_TYPE = gql`
  mutation UpdateUserProfileType($profileType: ProfileType!) {
    updateProfileType(profileType: $profileType)
  }
`;

export const UPDATE_USER_SPORT_CARD = gql`
  mutation UpdateUserSportsCard($url: AWSURL!) {
    updateSportsCard(url: $url)
  }
`;

export const UPDATE_USER_DEVICE_TOKEN = gql`
  mutation UpdateUserDeviceToken($deviceToken: String!) {
    updateDeviceToken(deviceToken: $deviceToken)
  }
`;

export const ASK_TO_JOIN_ACTIVITY = gql`
  mutation AskToJoinActivity($activityId: ID!, $message: String!) {
    askToJoin(activityId: $activityId, message: $message)
  }
`;

export const ACCEPT_JOIN_ACTIVITY_REQUEST = gql`
  mutation AcceptJoinActivityRequest($activityId: ID!, $requestId: ID!) {
    acceptJoinRequest(activityId: $activityId, requestId: $requestId)
  }
`;

export const ACCEPT_LETS_PLAY_REQUEST = gql`
  mutation AcceptLetsPlayRequest($requestId: ID!) {
    acceptLetsPlayRequest(requestId: $requestId)
  }
`;

export const DELETE_ACTIVITY = gql`
  mutation DeleteActivity($activityId: ID!) {
    deleteActivity(activityId: $activityId)
  }
`;

export const MUTE_ACTIVITY = gql`
  mutation MuteActivity($activityId: ID!) {
    muteActivity(activityId: $activityId)
  }
`;

export const UNMUTE_ACTIVITY = gql`
  mutation UnmuteActivity($activityId: ID!) {
    unmuteActivity(activityId: $activityId)
  }
`;

export const MUTE_CHAMPIONSHIP_TEAM_CHAT = gql`
  mutation MuteChampionshipTeamChat($teamId: ID!) {
    muteChampionshipTeamChat(teamId: $teamId)
  }
`;

export const UNMUTE_CHAMPIONSHIP_TEAM_CHAT = gql`
  mutation UnmuteChampionshipTeamChat($teamId: ID!) {
    unmuteChampionshipTeamChat(teamId: $teamId)
  }
`;

export const MUTE_CHAMPIONSHIP_MATCH_TEAM_CHAT = gql`
  mutation MuteChampionshipMatchChat($matchId: ID!) {
    muteChampionshipMatchChat(matchId: $matchId)
  }
`;

export const UNMUTE_CHAMPIONSHIP_MATCH_TEAM_CHAT = gql`
  mutation UnmuteChampionshipMatchChat($matchId: ID!) {
    unmuteChampionshipMatchChat(matchId: $matchId)
  }
`;

export const MARK_ACTIVITY_CHAT_AS_READ = gql`
  mutation MarkActivityChatAsRead($activityId: ID!) {
    markActivityChatAsRead(activityId: $activityId)
  }
`;

export const MARK_CHAMPIONSHIP_CHAT_AS_READ = gql`
  mutation MarkChampionshipTeamChatAsRead($teamId: ID!) {
    markChampionshipTeamChatAsRead(teamId: $teamId)
  }
`;

export const MARK_CHAMPIONSHIP_MATCH_CHAT_AS_READ = gql`
  mutation MarkChampionshipMatchChatAsRead($matchId: ID!) {
    markChampionshipMatchChatAsRead(matchId: $matchId)
  }
`;

export const MARK_PRIVATE_CHAT_AS_READ = gql`
  mutation MarkPrivateChatAsRead($userId: ID!) {
    markPrivateChatAsRead(userId: $userId)
  }
`;

export const REJECT_JOIN_ACTIVITY_REQUEST = gql`
  mutation RejectJoinActivityRequest($activityId: ID!, $requestId: ID!) {
    rejectJoinRequest(activityId: $activityId, requestId: $requestId)
  }
`;

export const REJECT_LETS_PLAY_REQUEST = gql`
  mutation RejectLetsPlayRequest($requestId: ID!) {
    rejectLetsPlayRequest(requestId: $requestId)
  }
`;

export const LEAVE_ACTIVITY = gql`
  mutation LeaveActivity($activityId: ID!) {
    leaveActivity(activityId: $activityId)
  }
`;

export const SEND_ACTIVITY_MESSAGE = gql`
  mutation SendActivityMessage($activityId: ID!, $message: String!) {
    sendActivityMessage(activityId: $activityId, message: $message)
  }
`;

export const SEND_ONE_TO_ONE_MESSAGE = gql`
  mutation SendOneToOneMessage($userId: ID!, $message: String!) {
    sendOneToOneMessage(userId: $userId, message: $message)
  }
`;

export const SEND_CHAMPIONSHIP_TEAM_MESSAGE = gql`
  mutation SendChampionshipTeamMessage($teamId: ID!, $message: String!) {
    sendChampionshipTeamMessage(teamId: $teamId, message: $message)
  }
`;

export const SEND_CHAMPIONSHIP_MATCH_TEAM_MESSAGE = gql`
  mutation SendChampionshipMatchMessage($matchId: ID!, $message: String!) {
    sendChampionshipMatchMessage(matchId: $matchId, message: $message)
  }
`;

export const SCAN_QR_CODE = gql`
  mutation ScanQRCode(
    $barcodeString: String!
    $university: String!
    $campus: String!
    $category: String!
    $sport: String!
    $classId: ID
  ) {
    scanBarcode(
      barcodeString: $barcodeString
      university: $university
      campus: $campus
      category: $category
      sport: $sport
      classId: $classId
    ) {
      id
      firstName
      lastName
      profilePic
      profileType
    }
  }
`;

export const CREATE_ACTIVITY = gql`
  mutation CreateActivity(
    $happeningAt: AWSDateTime!
    $sport: String!
    $location: LocationInput!
    $motivation: String!
  ) {
    addActivity(
      happeningAt: $happeningAt
      sport: $sport
      location: $location
      motivation: $motivation
    ) {
      activityId
      happeningAt
      hasUnreadMessages
      sport {
        displayName
      }
      motivation
      location {
        name
      }
      user {
        id
        firstName
        lastName
        profilePic
        sports {
          sport {
            name
            displayName
          }
          level
        }
      }
      members {
        id
        firstName
        lastName
        profilePic
        sports {
          sport {
            name
            displayName
          }
          level
        }
      }
    }
  }
`;

export const CALL_TEAM_FOR_MATCH = gql`
  mutation CallToMatch(
    $teamId: ID!
    $name: String!
    $location: LocationInput!
    $date: AWSDate!
    $time: AWSTime!
    $squad: [ID!]!
  ) {
    callToMatch(
      teamId: $teamId
      name: $name
      location: $location
      date: $date
      time: $time
      squad: $squad
    ) {
      matchId
      teamId
      name
      location {
        name
        latitude
        longitude
      }
      date
      time
      squad {
        id
        firstName
        lastName
        activitiesCount
        profileType
        university {
          name
          profileName
          centerName
        }
        gender
        sports {
          sport {
            name
            displayName
          }
          level
        }
      }
      coordinator {
        id
        firstName
        lastName
        activitiesCount
        profileType
        university {
          name
          profileName
          centerName
        }
        gender
        sports {
          sport {
            name
            displayName
          }
          level
        }
      }
    }
  }
`;

export const SET_MY_UNIVERSITY_PROFILE_TYPE = gql`
  mutation SetMyUniversityProfileType(
    $universityName: String!
    $profileType: String!
  ) {
    setMyUniversityProfileType(
      universityName: $universityName
      profileType: $profileType
    )
  }
`;

export const BOOK_CAMPUS_SPORT_CLASS = gql`
  mutation BookCampusSportClass($classId: ID!) {
    bookCampusSportClass(classId: $classId) {
      classId
      date
      startTime
      endTime
      name
      maxParticipants
      participantsCount
      isBooked
    }
  }
`;

export const UNBOOK_CAMPUS_SPORT_CLASS = gql`
  mutation UnBookCampusSportClass($classId: ID!) {
    unbookCampusSportClass(classId: $classId)
  }
`;

export const ADD_CHAMPIONSHIP_TEAM_MEMBER = gql`
  mutation AddChampionshipTeamMember($teamId: ID!, $userId: ID!) {
    addChampionshipTeamMember(teamId: $teamId, userId: $userId)
  }
`;

export const REMOVE_CHAMPIONSHIP_TEAM_MEMBER = gql`
  mutation RemoveChampionshipTeamMember($teamId: ID!, $userId: ID!) {
    removeChampionshipTeamMember(teamId: $teamId, userId: $userId)
  }
`;
