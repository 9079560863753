import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Logo from 'components/common/Logo';

const ContactUs = ({ history }) => {
  const handleClickLogo = useCallback(() => history.push('/'), [history]);

  return (
    <div style={{ padding: '80px' }}>
      <Logo onClick={handleClickLogo} />

      <>
        <p style={{ marginBottom: '0in' }}><font size={5} style={{ fontSize: '20pt' }}><b>Contact
          us </b></font>
        </p>
        <p style={{ marginTop: '0.17in', marginBottom: '0.32in', lineHeight: '100%' }}>
          <font color="#333333"><font size={3}>If you have any questions or
            feedback please feel free to contact us:</font></font></p>
        <p style={{ marginTop: '0.17in', marginBottom: '0.32in', lineHeight: '100%' }}>
          <font color="#333333"><font size={3}>By email: </font></font><a href="mailto:fattal.sprl@gmail.com"><font color="#1155cc"><font size={3}><u>fattal.sprl@gmail.com</u></font></font></a><font color="#333333"><font size={3} /></font>
        </p>
        <p style={{ marginBottom: '0in' }}><font color="#666666"><font face="Times New Roman, serif"><font size={3}><span style={{ background: '#ffffff' }}>©
        </span></font></font></font><font face="Times New Roman, serif"><font size={3}>2018-2020
          Fattal Sprl. All rights reserved.</font></font></p>
      </>
    </div>
  );
};

ContactUs.defaultProps = {
  history: {},
};

ContactUs.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default ContactUs;
