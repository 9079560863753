import React from 'react';
import PropTypes from 'prop-types';
import { Steps } from 'antd';
import { isMobileOnly } from 'react-device-detect';
import { STEPS_INFO } from 'constants/registrationSteps';

const { Step } = Steps;

const StepsList = ({ onChange, currentStep, lockedSteps = [] }) => {
  return (
    <Steps
      current={currentStep}
      direction={isMobileOnly ? 'vertical' : 'horizontal'}
      progressDot={dot => (dot)}
      onChange={onChange}
      className="site-navigation-steps"
    >
      {STEPS_INFO.map(step => (
        <Step
          title={step.title}
          key={`step-${step.stepIndex}`}
          description=""
          disabled={lockedSteps.includes(step.stepIndex)}
        />
      ))}
    </Steps>
  );
};

StepsList.defaultProps = {
  currentStep: null,
  onChange: null,
  lockedSteps: [],
};

StepsList.propTypes = {
  currentStep: PropTypes.number,
  onChange: PropTypes.func,
  lockedSteps: PropTypes.arrayOf(PropTypes.number),
};

export default StepsList;
