import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Logo from 'components/common/Logo';

const PrivacyPolicy = ({ history }) => {
  const handleClickLogo = useCallback(() => history.push('/'), [history]);

  return (
    <div style={{ padding: '80px' }}>
      <Logo onClick={handleClickLogo} />

      <>
        <p align="CENTER" style={{ marginBottom: '0.11in', lineHeight: '196%' }}><font
          size={5}
          style={{ fontSize: '20pt' }}
        ><u><span
          style={{ background: '#ffffff' }}
        >Privacy
          policy</span></u></font></p>
        <p style={{ marginBottom: '0.11in', lineHeight: '176%' }} />
        <p style={{ marginBottom: '0.11in', lineHeight: '157%' }}><font size={4} style={{ fontSize: '16pt' }}><u><span
          style={{ background: '#ffffff' }}
        >1.
          Introduction</span></u></font></p>
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }} />
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >Effective
          Date: 6</span></font><sup><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
          >th</span></font></sup><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
          >
            of September, 2020</span></font></p>
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }} />
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >Nova
          Sport (“we”, “us” or “our”) operates the Nova Sport
          mobile application (the “Service”).</span></font></p>
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }} />
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >This
          document informs you of our policy regarding the collection, use and
          disclosure of personal data when you use our service and the choices
          you have associated with that data.</span></font></p>
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }} />
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >We
          use your data to provide and improve the Service. By using the
          Service, you agree to the collection and use of information in
          accordance with this policy. Unless otherwise specified in this
          privacy policy, terms used in this privacy policy have the same
          meaning as in our terms and conditions.</span></font></p>
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }} />
        <p style={{ marginBottom: '0.11in', lineHeight: '157%' }}><font size={4} style={{ fontSize: '16pt' }}><u><span
          style={{ background: '#ffffff' }}
        >2.
          Collection and use of information</span></u></font></p>
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }} />
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >We
          collect many types of information for different purposes to provide
          you with and improve our service.</span></font></p>
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }} />
        <p style={{ marginBottom: '0.11in', lineHeight: '137%' }}><font size={4}><u><span style={{ background: '#ffffff' }}>2.1.
          Types of data collected</span></u></font></p>
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }} />
        <p style={{ marginBottom: '0.11in', lineHeight: '137%' }}><font size={4}><span style={{ background: '#ffffff' }}>a)
          General identification data</span></font></p>
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }} />
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >When
          using our Service, we may ask you to provide us with certain general
          identifying information that can be used to contact or identify you
          (“Personal Data”). Personal identifiable information may include:
        </span></font>
        </p>
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >·
          E-mail address</span></font></p>
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >·
          First and last name</span></font></p>
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >.
          Profile and cover photo</span></font></p>
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >.
          Postal code, city, country</span></font></p>
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >.
          Age and gender</span></font></p>
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }} />
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }} />
        <p style={{ marginBottom: '0.11in', lineHeight: '137%' }}><font size={4}><span style={{ background: '#ffffff' }}>b)
          Other data</span></font></p>
        <p style={{ marginBottom: '0.11in', lineHeight: '137%' }} />
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >.
          Name of university / high school</span></font></p>
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >.
          Your sports and your level</span></font></p>
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >.
          Usage data</span></font></p>
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }} />
        <p style={{ marginBottom: '0.11in', lineHeight: '137%' }}><font size={4}><span style={{ background: '#ffffff' }}>c)
          Usage data</span></font></p>
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }} />
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >When
          you access the Service by or through a mobile device, we may collect
          certain information automatically, including, the type of mobile
          device you are using, the unique ID of your mobile device, the IP
          address of your mobile device, your mobile operating system, the type
          of mobile internet browser you are using, unique device identifiers
          and other diagnostic data ("Usage Data").</span></font></p>
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }} />
        <p style={{ marginBottom: '0.11in', lineHeight: '137%' }}><font size={4}><u><span style={{ background: '#ffffff' }}>2.2.
          Purposes pursued</span></u></font></p>
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }} />
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >The
          Nova Sport application uses the collected data for various purposes:</span></font></p>
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }} />
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >.
          Provide and maintain the Service</span></font></p>
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >.
          To notify you of changes to our service</span></font></p>
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >.
          To allow you to participate in the interactive features of our
          Service when you wish</span></font></p>
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >.
          To provide support to users</span></font></p>
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >.
          To provide analysis or valuable information so that we can improve
          the service</span></font></p>
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >.
          To monitor the use of the Service</span></font></p>
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >.
          To detect, prevent and resolve technical problems</span></font></p>
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >.
          To do statistics on anonymized data.</span></font></p>
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }} />
        <p style={{ marginBottom: '0.11in', lineHeight: '157%' }}><font size={4} style={{ fontSize: '16pt' }}><u><span
          style={{ background: '#ffffff' }}
        >3.
          Transfer of personal data outside the EU</span></u></font></p>
        <p style={{ marginBottom: '0.11in', lineHeight: '176%' }} />
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >If
          you are located outside Belgium and choose to provide information to
          us, please note that we transfer the data, including Personal Data,
          to Belgium and process it there.</span></font></p>
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }} />
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >The
          application Nova Sport will take all steps reasonably necessary to
          ensure that your data is treated securely and in accordance with this
          privacy policy.</span></font></p>
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >No
          transfer of personal data is made to a third country or an
          international organization.</span></font></p>
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }} />
        <p style={{ marginBottom: '0.11in', lineHeight: '157%' }}><font size={4} style={{ fontSize: '16pt' }}><u><span
          style={{ background: '#ffffff' }}
        >4.
          Communication of personal data</span></u></font></p>
        <p style={{ marginBottom: '0.11in', lineHeight: '147%' }} />
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >Public
          authorities may be required to have access to this personal data when
          there is a legal obligation in this regard or in order to defend the
          rights or property of the Nova Sport application, or to protect the
          personal safety of users of service.</span></font></p>
        <p style={{ marginBottom: '0.11in', lineHeight: '157%' }} />
        <p style={{ marginBottom: '0.11in', lineHeight: '157%' }}><font size={4} style={{ fontSize: '16pt' }}><u><span
          style={{ background: '#ffffff' }}
        >5.
          Users’ rights</span></u></font></p>
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }} />
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >In
          accordance with the GDPR, users have the following rights: </span></font>
        </p>
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }} />
        <p
          align="JUSTIFY"
          style={{
            marginLeft: '0.75in', textIndent: '-0.25in', marginBottom: '0in', lineHeight: '115%',
          }}
        >
          <font face="Calibri, serif"><span style={{ background: '#ffffff' }}>1) </span></font><span
          style={{ background: '#ffffff' }}
          >A
            right of access to their personal data</span>: This is the right to
          obtain confirmation that personal data concerning them are or are not
          being processed and, when they are, the right to obtain the access to
          this data.<font size={1}> </font>
        </p>
        <p
          align="JUSTIFY"
          style={{
            marginLeft: '0.75in', textIndent: '-0.25in', marginBottom: '0in', lineHeight: '115%',
          }}
        >
          <font face="Calibri, serif">2) </font><span style={{ background: '#ffffff' }}>A
            right of rectification: </span>This is the right to obtain the
          rectification of their inaccurate personal data, and this as soon as
          possible. It is also about the right to complete them if they are
          incomplete.<font size={1}> </font><span style={{ background: '#ffffff' }} />
        </p>
        <p
          align="JUSTIFY"
          style={{
            marginLeft: '0.75in', textIndent: '-0.25in', marginBottom: '0in', lineHeight: '115%',
          }}
        >
          <font face="Calibri, serif"><span style={{ background: '#ffffff' }}>3) </span></font><span
          style={{ background: '#ffffff' }}
          >A
            right to erasure: </span>Subject to the limiting conditions provided
          for by applicable regulations, they may request the erasure of
          personal data concerning them.<font size={1}> </font><span style={{ background: '#ffffff' }} />
        </p>
        <p
          align="JUSTIFY"
          style={{
            marginLeft: '0.75in', textIndent: '-0.25in', marginBottom: '0in', lineHeight: '115%',
          }}
        >
          <font face="Calibri, serif"><span style={{ background: '#ffffff' }}>4) </span></font><span
          style={{ background: '#ffffff' }}
          >A
            right to limit processing: </span>Subject to the limiting conditions
          provided for by applicable regulations, they may obtain the
          restriction of the processing of personal data concerning them.<font size={1} /><span
          style={{ background: '#ffffff' }}
          > </span>
        </p>
        <p
          align="JUSTIFY"
          style={{
            marginLeft: '0.75in', textIndent: '-0.25in', marginBottom: '0in', lineHeight: '115%',
          }}
        >
          <font face="Calibri, serif"><span style={{ background: '#ffffff' }}>5) </span></font><span
          style={{ background: '#ffffff' }}
          >A
            right to portability: </span>Subject to the limiting conditions
          provided for by the applicable regulations, they may request to
          exercise their right to the portability of their data, that is to say
          the right to receive their personal data in a structured format,
          commonly used and the right to transmit this data to another data
          controller.<font size={1}> </font><span style={{ background: '#ffffff' }} />
        </p>
        <p
          align="JUSTIFY"
          style={{
            marginLeft: '0.75in', textIndent: '-0.25in', marginBottom: '0in', lineHeight: '115%',
          }}
        >
          <font face="Calibri, serif"><span style={{ background: '#ffffff' }}>6) </span></font><span
          style={{ background: '#ffffff' }}
          >The
            right to lodge a complaint with the Data Protection Authority by
            following the procedure provided on the page
          </span>&nbsp;<a
          target="_blank"
          rel="noopener noreferrer"
          href="https://translate.google.com/translate?hl=en&prev=_t&sl=fr&tl=en&u=https://www.autoriteprotectiondonnees.be/citoyen/agir/introduire-une-plainte"
          ><font
          color="#1155cc"
          ><u>https://www.autoriteprotectiondonnees.be/citoyen/agir/introduire-une-plainte</u></font></a><font
          size={1}
          />
        </p>
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font color="#00b050" /><br /><br />
        </p>
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >To
          exercise these rights, users are invited to contact us on
        </span></font><font color="#1155cc"><font face="Calibri, serif">&nbsp;<a href="mailto:fattal.sprl@gmail.com">fattal.sprl@gmail.com</a></font></font></p>
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }} />
        <p style={{ marginBottom: '0.11in', lineHeight: '157%' }}><font size={4} style={{ fontSize: '16pt' }}><u><span
          style={{ background: '#ffffff' }}
        >6.
          Retention period of personal data</span></u></font></p>
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif">Personal
          data is kept for a period of 3 years after using the service. After 3
          years of inactivity, it will be deleted from the database.</font></p>
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }} />
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif">The
          data controller reserves the right to anonymize usage data in order
          to keep them for their statistical value.</font></p>
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }} />
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif">The
          action of rectifying, erasing or restoring your personal data will be
          carried out during a period of 1 month. To exercise these rights,
          users are invited to contact us </font><font color="#0563c1"><font
          face="Calibri, serif"
          >&nbsp;<a href="mailto:fattal.sprl@gmail.com">fattal.sprl@gmail.com</a></font></font></p>

        <p style={{ marginBottom: '0.11in', lineHeight: '157%' }}><font size={4} style={{ fontSize: '16pt' }}><u><span
          style={{ background: '#ffffff' }}
        >7.
          Data security</span></u></font></p>
        <p style={{ marginBottom: '0.11in', lineHeight: '147%' }} />
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >The
          security of your data is important to us, but remember that no method
          of transmission over the Internet or method of electronic storage is
          100% secure. Although we endeavor to use commercially acceptable
          means to protect your personal data, we cannot guarantee its absolute
          security.</span></font></p>
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '176%' }} />
        <p style={{ marginBottom: '0.11in', lineHeight: '157%' }}><font size={4} style={{ fontSize: '16pt' }}><u><span
          style={{ background: '#ffffff' }}
        >8.
          Links to other sites</span></u></font></p>
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }} />
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >Our
          service may contain links to other sites, which are not operated by
          us. If you click on a third party link, you will be directed to that
          third party's site. We strongly recommend that you review the privacy
          policy of each site you visit.</span></font></p>
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >We
          have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party site or service.</span></font></p>
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >For
          example, we can put a link in the sports program of a university
          center to redirect you to their official website or to have more
          information.</span></font></p>
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }} />
        <p style={{ marginBottom: '0.11in', lineHeight: '157%' }}><font size={4} style={{ fontSize: '16pt' }}><u>9.
        </u></font><font size={4} style={{ fontSize: '16pt' }}><u><span style={{ background: '#ffffff' }}>Child
          protection</span></u></font></p>
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }} />
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >Our
          Service is not intended for anyone under the age of 16 (“Children”).</span></font></p>
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >We
          do not knowingly collect personally identifiable information from
          anyone under the age of 16. If you are a parent or guardian and know
          that your children have provided us with personal data, please
          contact us. If we learn that we have collected personal data from
          children without verification of parental consent, we take steps to
          delete this information from our servers.</span></font></p>
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }} />
        <p style={{ marginBottom: '0.11in', lineHeight: '157%' }}><font size={4} style={{ fontSize: '16pt' }}><u><span
          style={{ background: '#ffffff' }}
        >10.
          Changes to this privacy policy</span></u></font></p>
        <p style={{ marginBottom: '0.11in', lineHeight: '107%' }} />
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >We
          may update our privacy policy from time to time. We will notify you
          of any changes by posting the new privacy policy on this page.</span></font></p>
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >We
          will notify you by email and / or a prominent notice on our Service,
          before the change becomes effective and update the "effective
          date" at the top of this Privacy Policy.</span></font></p>
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{ background: '#ffffff' }}
        >We
          advise you to regularly review this privacy policy for any changes.
          Changes to this privacy policy are effective when posted on this
          page.</span></font></p>
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }} />
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }}><font face="Calibri, serif"><span
          style={{
            background: '#ffffff',
            textAlign: 'center',
            display: 'inline-block',
            width: '100%',
          }}
        >©
          2018-2020 Fattal Sprl. All rights reserved.</span></font></p>
        <p align="JUSTIFY" style={{ marginBottom: '0.11in', lineHeight: '107%' }}><br /><br />
        </p>
        <p style={{ marginBottom: '0in' }}><br />
        </p>
      </>
    </div>

  );
};

PrivacyPolicy.defaultProps = {
  history: {},
};

PrivacyPolicy.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default PrivacyPolicy;
