import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ApolloConsumer } from 'react-apollo';
import { Upload, message } from 'antd';
import { LoadingOutlined, CameraOutlined, EditOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import classNames from 'classnames';
import {
  GET_UPLOAD_URL,
} from 'api/queries';

const IMAGE_CONTENT_TYPE = 'image/jpeg; charset=utf-8';

const MAX_FILE_SIZE_MB = 10;

const AVATAR_IMG_ID = 'avatar-image-id';

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isSizeOk = file.size / 1024 / 1024 < MAX_FILE_SIZE_MB;
  if (!isSizeOk) {
    message.error(`Image must smaller than ${MAX_FILE_SIZE_MB} MB!`);
  }
  return isJpgOrPng && isSizeOk;
};

const AvatarUploader = ({
   value: imageUrl,
   onImageUploaded,
   name = 'avatar',
   className = '',
   showIcon = false,
  }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [imgSrc, setImgSrc] = useState(imageUrl);

  useEffect(() => {
    setImgSrc(imageUrl);
  }, [imageUrl]);

  const handleChange = async (info, client) => {
    if (info.file.status === 'uploading') {
      setIsLoading(true);
      const reader = new FileReader();
      const { file } = info;
      reader.readAsArrayBuffer(file.originFileObj);
      reader.onload = async () => {
        const extension = file.originFileObj.name.split('.')[1];
        const { data } = await client.query({
          query: GET_UPLOAD_URL,
          variables: { extension, contentType: IMAGE_CONTENT_TYPE },
          fetchPolicy: 'network-only',
        });
        const { getUploadUrl: url } = data;
        const arrayBuffer = reader.result;

        await fetch(url, {
          method: 'PUT',
          body: arrayBuffer,
          headers: {
            'Content-Type': IMAGE_CONTENT_TYPE,
          },
        });
        const urlWithoutParameters = url.split('?')[0];
        onImageUploaded(urlWithoutParameters);
        setIsLoading(false);
        setImgSrc(urlWithoutParameters);
      };
    }
  };

  const uploadButton = (
    <div>
      {isLoading ? <LoadingOutlined /> : <CameraOutlined />}
    </div>
  );

  return (
    <ApolloConsumer>
      {(client) => (
        <ImgCrop rotate grid shape>
          <Upload
            listType="picture-card"
            className={classNames({
              [className]: !!classNames,
              borderless: !!imgSrc,
            })}
            customRequest={() => {
            }}
            name={name}
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={info => handleChange(info, client)}
          >
            {imgSrc
              ? (
                <>
                  <img
                    src={imgSrc}
                    alt="avatar"
                    style={{ width: '100%' }}
                    id={AVATAR_IMG_ID}
                  />
                  {showIcon && <div className="edit"><EditOutlined /></div>}
                </>
              )
              : uploadButton}
          </Upload>
        </ImgCrop>
      )}
    </ApolloConsumer>
  );
};

AvatarUploader.defaultProps = {
  value: null,
  onImageUploaded: null,
  name: null,
  className: '',
  showIcon: null,
};

AvatarUploader.propTypes = {
  value: PropTypes.oneOfType([null, PropTypes.string]),
  onImageUploaded: PropTypes.func,
  name: PropTypes.string,
  className: PropTypes.string,
  showIcon: PropTypes.bool,
};

export default AvatarUploader;
