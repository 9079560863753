import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Layout, Input } from 'antd';
import Logo from 'components/common/Logo';
import {
  ROUTE_BOOKING,
  ROUTE_MY_PROFILE,
  ROUTE_SELECT_UNIVERSITY,
  ROUTE_UNIVERSITY,
} from 'constants/routes';
import { SearchOutlined } from '@ant-design/icons';
import BookingProvider from 'contextProviders/BookingProvider';
import SiderMenu from './SiderMenu';
import HeaderMenu from './HeaderMenu';
import MyProfile from './MyProfile';
import SelectUniversity from './University/SelectUniversity';
import University from './University';
import Booking from './Booking';

const { Header, Footer, Sider } = Layout;

const DashboardLayout = ({
  history,
  checkIfIsLoggedIn,
  match,
  match: {
    path,
    params: { universityName },
  },
}) => {
  const handleClickLogo = useCallback(
    () => history.push(ROUTE_MY_PROFILE),
    [history],
  );

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider width={300}>
        <div className="logo" />
        <Logo
          onClick={handleClickLogo}
          style={{
            position: 'relative',
            top: 0,
            left: '26px',
            height: '40px',
          }}
        />
        <SiderMenu match={match} history={history} />
      </Sider>
      <Layout className="site-layout">
        <Header>
          <div>
            <div className="hide">
              <Input
                allowClear
                placeholder="Search"
                prefix={<SearchOutlined />}
                bordered={false}
                style={{ width: '300px', fontSize: '22px' }}
                className="hide"
              />
            </div>
          </div>
          <HeaderMenu history={history} checkIfIsLoggedIn={checkIfIsLoggedIn} />
        </Header>

        {path === ROUTE_MY_PROFILE && <MyProfile history={history} />}
        {path === ROUTE_SELECT_UNIVERSITY && (
          <SelectUniversity history={history} />
        )}
        <BookingProvider>
          {path === ROUTE_UNIVERSITY && (
            <University history={history} universityName={universityName} />
          )}
          {path === ROUTE_BOOKING && <Booking />}
        </BookingProvider>

        <Footer style={{ textAlign: 'center' }}>&nbsp;</Footer>
      </Layout>
    </Layout>
  );
};

DashboardLayout.defaultProps = {
  match: null,
  history: null,
  checkIfIsLoggedIn: null,
};

DashboardLayout.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.shape({
      universityName: PropTypes.string,
    }),
  }),
  checkIfIsLoggedIn: PropTypes.func,
};

export default DashboardLayout;
