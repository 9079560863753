import React, {
  useState, useCallback, useContext, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'antd';
import {
  EditOutlined,
  QrcodeOutlined,
  EnvironmentOutlined,
  HomeOutlined,
} from '@ant-design/icons';
import AvatarUploader from 'components/common/AvatarUploader';
import { ProfileContext } from 'contextProviders/ProfileProvider';
import { EditProfileContext } from 'contextProviders/EditProfileProvider';
import { ROUTE_BOOKING } from 'constants/routes';
import EditProfileTypeModal from './EditProfileTypeModal';
import EditProfileModal from './EditProfileModal';
import styles from './index.module.scss';

const Profile = ({ history }) => {
  const {
    fetchedMyProfile,
    fetchedMyProfile: {
      getMyProfile: {
        profilePic,
        firstName,
        lastName,
        location: { name: locationName } = {},
        university,
        profileType,
      } = {},
    } = {},
  } = useContext(ProfileContext);
  const { updateProfilePhoto } = useContext(EditProfileContext);

  const { centerName } = university || {};

  const [avatarUrl, setAvatarUrl] = useState(null);
  const [editProfileVisible, setEditProfileVisible] = useState(false);
  const [editProfileTypeVisible, setEditProfileTypeVisible] = useState(false);

  useEffect(() => {
    if (profilePic) setAvatarUrl(profilePic);
  }, [profilePic]);

  const onImageUploaded = useCallback(
    async (url) => {
      if (url) {
        await updateProfilePhoto({ variables: { url } });
        await fetchedMyProfile.refetch();
      }
    },
    [fetchedMyProfile, updateProfilePhoto],
  );

  const handleEditProfileModal = useCallback(
    (e) => {
      e.preventDefault();
      setEditProfileVisible(true);
    },
    [setEditProfileVisible],
  );

  const handleEditProfileTypeModal = useCallback(
    () => setEditProfileTypeVisible(true),
    [setEditProfileTypeVisible],
  );

  const handleQRCodeModal = useCallback(
    (e) => {
      e.preventDefault();
      if (profileType) {
        history.push(ROUTE_BOOKING);
      } else {
        handleEditProfileTypeModal();
      }
    },
    [handleEditProfileTypeModal, profileType],
  );

  return (
    <>
      <div className={styles.wrapperProfile}>
        <Row type="flex">
          <Col>
            <AvatarUploader
              value={avatarUrl}
              onImageUploaded={onImageUploaded}
              showIcon
            />
          </Col>
          <Col className={styles.profile}>
            <div>
              <h1>
                {firstName} {lastName}
              </h1>
            </div>
            <div className={styles.profileItem}>
              <HomeOutlined />
              <span>{centerName}</span>
            </div>
            <div className={styles.profileItem}>
              <EnvironmentOutlined />
              <span>{locationName}</span>
            </div>
          </Col>
          <Col className={styles.right}>
            <Button type="primary" onClick={handleQRCodeModal}>
              <QrcodeOutlined />
              <span>Download QR Code</span>
            </Button>
            <a onClick={handleEditProfileModal} href="/">
              <EditOutlined />
              <span>Edit</span>
            </a>
          </Col>
        </Row>
      </div>
      {editProfileVisible && (
        <EditProfileModal hideModal={() => setEditProfileVisible(false)} />
      )}
      {editProfileTypeVisible && (
        <EditProfileTypeModal
          history={history}
          hideModal={() => setEditProfileTypeVisible(false)}
        />
      )}
    </>
  );
};

Profile.defaultProps = {
  history: null,
};

Profile.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default Profile;
