import React, { useState, useEffect, useCallback } from 'react';
import {
  Layout,
  Avatar,
  Row,
  Col,
  Select,
} from 'antd';
import { graphql } from 'react-apollo';
import moment from 'moment';
import {
  AntDesignOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { GET_UNIVERSITIES_LIST } from 'api/queries';
import {
  ROUTE_SELECT_UNIVERSITY,
} from 'constants/routes';
import PropTypes from 'prop-types';
import HelperSportClassesDates from './helpers/HelperSportClassesDates';
import Sport from './Sport';
import styles from './index.module.scss';
import SportClasses from './SportClasses';

const { Content } = Layout;
const { Option } = Select;

const University = ({
 history, universityName, fetchedUniversities: {
  listUniversities: {
    universities = [],
  } = {},
} = {},
}) => {
  const [university, setUniversity] = useState({});
  const { campuses: { campuses = [] } = {} } = university;
  const [campus, setCampus] = useState();
  const [category, setCategory] = useState();
  const [sport, setSport] = useState();
  const [date, setDate] = useState();
  const [availableDates, setAvailableDates] = useState([]);
  const [isSearchDates, setIsSearchDates] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDateSelectorVisible, setIsDateSelectorVisible] = useState(false);

  useEffect(() => {
    if (universities.length > 0) {
      const search = universities?.find(u => u?.name === universityName) || {};
      setUniversity(search);
    }
  }, [universityName, universities, setUniversity]);

  const handleChangeCampus = useCallback(value => {
    setIsDateSelectorVisible(false);
    setAvailableDates([]);
    setDate(null);
    setIsSearchDates(false);
    setSport(null);
    setCategory(null);
    if (campuses?.length > 0) {
      const search = campuses?.find(c => value === c?.name);
      setCampus(search);
    }
  }, [
    campuses,
    setCampus,
  ]);

  const handleChangeCategory = useCallback(value => {
    setIsDateSelectorVisible(false);
    setAvailableDates([]);
    setDate(null);
    setIsSearchDates(false);
    setSport(null);
    if (campus?.categories.length > 0) {
      const search = campus?.categories?.find(c => value === c?.name);
      setCategory(search);
    }
  }, [
    campus,
    setCategory,
  ]);

  const handleChangeSport = useCallback(value => {
    setIsDateSelectorVisible(false);
    setIsSearchDates(false);
    setDate(null);
    setAvailableDates([]);
    if (category?.sports) {
      const search = category?.sports?.find(s => value === s?.name);
      setSport(search);
    }
  }, [
    category,
    setSport,
  ]);

  const handleChangeDate = useCallback(value => setDate(value), []);

  useEffect(() => {
    if (campus?.name && category?.name && sport?.name) {
      const timeoutId = setTimeout(() => {
        setIsSearchDates(true);
      }, 100);
      return () => clearTimeout(timeoutId);
    }
    setIsSearchDates(false);
  }, [campus, category, sport]);

  return (
    <>
      <Content>
        <div className="site-layout-background">
          <div className="university-select">
            <div
            className={styles.back}
            onClick={() => history.push(ROUTE_SELECT_UNIVERSITY)}
            >
              <LeftOutlined /> Back
            </div>
          </div>

          <div
          className={styles.wrapperUniversityInfo}
          style={{
            '--color': '#949494',
            '--image': `url(${university?.coverPhoto})`,
            '--image2': `url(${university?.coverPhoto})`,
            '--blend-top': 'multiply',
            '--blend-bottom': 'multiply',
            '--color-v': 'transparent',
          }}
          >
            <Avatar
            icon={<AntDesignOutlined />}
            size={104}
            src={university?.profilePic}
            />
            <div className={styles.universityLabel}>{university.centerName}</div>
          </div>

          <br />

          <Row className="filter" gutter={[16, 16]}>
            <Col span={8}>
              <Select
              // size="small"
              showSearch
              allowClear
              style={{ width: '100%' }}
              placeholder="Select a campus"
              optionFilterProp="children"
              onChange={handleChangeCampus}
              value={campus?.name}
              disabled={campuses?.length === 0 || isLoading}
              >
                {campuses?.map(c => (
                  <Option
                value={c.name}
                key={c.name}
                  >{c.displayName}</Option>
              ))}
              </Select>
            </Col>
            <Col span={8}>
              <Select
              showSearch
              allowClear
              style={{ width: '100%' }}
              placeholder="Select a category"
              optionFilterProp="children"
              onChange={handleChangeCategory}
              value={category?.name}
              disabled={!campus || isLoading}
              >
                {campus?.categories?.map(c => (
                  <Option
                  value={c.name}
                  key={c.name}
                  >{c.name}</Option>
              ))}
              </Select>
            </Col>
            <Col span={8}>
              <Select
              showSearch
              allowClear
              style={{ width: '100%' }}
              placeholder="Select a sport"
              optionFilterProp="children"
              onChange={handleChangeSport}
              value={sport?.name}
              disabled={!category || isLoading}
              >
                {category?.sports?.map(s => (
                  <Option
                  value={s.name}
                  key={s.name}
                  >{s.name}</Option>
              ))}
              </Select>
            </Col>
            {isDateSelectorVisible && (
            <Col span={8} offset={8}>
              <Select
                // size="small"
                showSearch
                allowClear
                style={{ width: '100%' }}
                placeholder="Select a date"
                optionFilterProp="children"
                onChange={handleChangeDate}
                value={date}
                disabled={!sport || isLoading}
              >
                {availableDates?.map(d => (
                  <Option
                    value={d}
                    key={d}
                  >{moment(d, 'YYYY-MM-DD').format('DD/MM/YYYY dddd ')}</Option>
                ))}
              </Select>
            </Col>
            )}
          </Row>

          {sport && !isDateSelectorVisible && (
          <Sport
            sport={sport}
            showDateSelector={() => setIsDateSelectorVisible(true)}
          />
          )}

          {isDateSelectorVisible && date && (
            <SportClasses
            university={university.name}
            campus={campus.name}
            category={category.name}
            sport={sport.name}
            date={date}
            setIsLoading={setIsLoading}
            />
          )}

        </div>
      </Content>

      {isSearchDates && (
        <HelperSportClassesDates
          university={university.name}
          campus={campus.name}
          category={category.name}
          sport={sport.name}
          setIsLoading={setIsLoading}
          setAvailableDates={setAvailableDates}
        />
      )}
    </>
  );
};

University.defaultProps = {
  history: null,
  universityName: null,
};

University.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  universityName: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  fetchedUniversities: PropTypes.object,
};

const withGetUniversities = graphql(GET_UNIVERSITIES_LIST, {
  name: 'fetchedUniversities',
});

export default withGetUniversities(University);
